// import React, { useState, useEffect, useContext } from "react";
// import { Link, Navigate } from "react-router-dom";
// import MyButton from "../componant/MyButton";
// import productContext from "../context/Product/productContext";
// import Noticontext from "../context/notification/NotifContext";
// import "../css/slider.css";
// import ComContext from "../context/Company/CompanyContext";
// // import orderconfirm from "../svgicon/confirmed.svg";
// import InfiniteScroll from "react-infinite-scroll-component";
// import OrderContext from "../context/Order/OrderCon";
// import CustomButton from "../componant/CustomButton";
// import formatDateTime from "../staticFunc/DateFormat";
// import Search_Query from "../componant/search_query/Search_Query";

import React, { useContext, useEffect, useState } from "react";
import Search_Query from "../componant/search_query/Search_Query";
import productContext from "../context/Product/productContext";
import formatDateTime from "../staticFunc/DateFormat";
import InfiniteScroll from "react-infinite-scroll-component";
import "../css/slider.css";
//require("dotenv").config();
export default function Inventory1(props) {
  const mycontext = useContext(productContext);
  const { product, totalCount, totalInventory, getAllProduct, updateProduct } =
    mycontext;

  const [showInventoryDialog, setShowInventoryDialog] = useState(false);

  const [editPro, setEditPro] = useState(null);

  const open = (obj) => {
    setShowInventoryDialog(!showInventoryDialog);
    setEditPro(obj);
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  //   load more function
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const fetchFunction = (que) => {
    console.log(
      "load more function called" + product.length + " - " + totalCount
    );
    if (product.length === totalCount) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
      setPage(page + 8);
      getAllProduct(true, page + 8, que, true, (l_length) => {
        if (l_length === 0) {
          setLoadMore(false);
        }
      });
    }
  };

  return (
    <>
      {showInventoryDialog && (
        <InventoryDialog
          open={() => {
            setShowInventoryDialog(!showInventoryDialog);
          }}
          alert={(msg) => {
            props.showAlert(msg, "success");
          }}
          data={editPro}
          type={editPro ? editPro.type : "reduce"}
        />
      )}
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12">
              <h4 className=" c_heading">
                <span className="">Inventory ({totalInventory})</span>
              </h4>
            </div>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-12 text-right">
                      <Search_Query
                        value={query}
                        show={query !== ""}
                        onQuery={setQuery}
                        clear_data={() => {
                          setQuery("");
                          setPage(1);
                          setLoadMore(true);
                          getAllProduct(false, 1, "");
                        }}
                        search_click={() => {
                          getAllProduct(false, 1, query);
                          setPage(1);
                          setLoadMore(true);
                        }}
                        placeholder="Search by Product Name ..."
                      />
                      {/* <input
                        type="text"
                        className="form-control search"
                        placeholder="Search by Product Name, sku, Status, etc..."
                        onChange={(e) => setQuery(e.target.value)}
                      /> */}
                    </div>

                    <div className="col-lg-6 font-bold text-right"></div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <InfiniteScroll
                    hasMore={loadMore}
                    next={() => {
                      fetchFunction(query);
                    }}
                    dataLength={product.length}
                    loader={
                      <>
                        <div
                          className="loader"
                          style={{
                            position: "relative",
                            width: 40,
                            height: 40,
                          }}
                        ></div>
                        <span>
                          If not loaded data{" "}
                          <strong
                            onClick={() => {
                              fetchFunction(query);
                            }}
                          >
                            Click Here
                          </strong>
                        </span>
                      </>
                    }
                  >
                    <table className="table table-hover">
                      <thead className="order_history_header">
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0 order_history_body">
                        {product.map((pro, index) => {
                          if (pro.p_price.length !== 0) {
                            return pro.p_price.map((attr, i) => {
                              return (
                                <tr key={attr._id}>
                                  <td>
                                    <img
                                      src={
                                        pro.p_gallery_image.length !== 0
                                          ? pro.p_gallery_image[0].link
                                          : "../assets/img/avatars/5.png"
                                      }
                                      alt="Avatar"
                                      width="60"
                                      height="60"
                                      className="rounded-circle customer_image my-auto"
                                    />
                                  </td>
                                  <td style={{ textWrap: "wrap" }}>
                                    <span className="font-bold">
                                      {pro.p_name}
                                    </span>
                                    <small className="d-block ">
                                      {attr.name}
                                    </small>
                                  </td>
                                  <td>
                                    {attr.inventory}{" "}
                                    <small style={{ color: "blue" }}>
                                      {pro.unit_name}
                                    </small>
                                    <small className="d-block ">
                                      {formatDateTime(attr.inv_update_date)}
                                    </small>
                                  </td>
                                  <td>
                                    <span
                                      className="d-block badge bg-danger px-2 mb-2"
                                      style={{ width: "fit-content" }}
                                      onClick={() => {
                                        open({
                                          ...pro,
                                          attr: "attr",
                                          attr_id: attr._id,
                                          type: "reduce",
                                        });
                                      }}
                                    >
                                      -
                                    </span>
                                    <span
                                      className="d-block badge bg-success px-2"
                                      style={{ width: "fit-content" }}
                                      onClick={() => {
                                        open({
                                          ...pro,
                                          attr: "attr",
                                          attr_id: attr._id,
                                          type: "add",
                                        });
                                      }}
                                    >
                                      +
                                    </span>
                                  </td>
                                </tr>
                              );
                            });
                          } else {
                            return (
                              <tr key={pro._id}>
                                <td>
                                  <img
                                    src={
                                      pro.p_gallery_image.length !== 0
                                        ? pro.p_gallery_image[0].link
                                        : "../assets/img/avatars/5.png"
                                    }
                                    alt="Avatar"
                                    width="60"
                                    height="60"
                                    className="rounded-circle customer_image my-auto"
                                  />
                                </td>
                                <td style={{ textWrap: "wrap" }}>
                                  <span className="font-bold">
                                    {pro.p_name}
                                  </span>
                                  <small className="d-block ">
                                    {" "}
                                    No Attribute
                                  </small>
                                </td>
                                <td>
                                  {pro.inventory}{" "}
                                  <small style={{ color: "blue" }}>
                                    {pro.unit_name}
                                  </small>
                                  <small className="d-block ">
                                    {formatDateTime(pro.inv_update_date)}
                                  </small>
                                </td>
                                <td>
                                  <span
                                    className="d-block badge bg-danger px-2 mb-2"
                                    style={{ width: "fit-content" }}
                                    onClick={() => {
                                      open({
                                        ...pro,
                                        attr: "noattr",
                                        attr_id: "",
                                        type: "reduce",
                                      });
                                    }}
                                  >
                                    -
                                  </span>
                                  <span
                                    className="d-block badge bg-success px-2"
                                    style={{ width: "fit-content" }}
                                    onClick={() => {
                                      open({
                                        ...pro,
                                        attr: "noattr",
                                        attr_id: "",
                                        type: "add",
                                      });
                                    }}
                                  >
                                    +
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            {/* table layout */}
          </div>
        </div>
      </div>
    </>
  );
}

const InventoryDialog = ({
  open,
  data = null,
  alert = null,
  type = "Reduce",
}) => {
  const inventory = parseInt(
    data.attr === "noattr"
      ? data.inventory
      : data.p_price.find((pro) => pro._id === data.attr_id).inventory
  );
  const [newStock, setNewStock] = useState(inventory);

  const [sendData, setSendData] = useState({
    p_id: data._id,
    attr_id:
      data.attr === "noattr"
        ? ""
        : data.p_price.find((pro) => pro._id === data.attr_id)._id,
    inventory: "",
    date: "",
  });

  const onChangeData = (e) => {
    if (e.target.name === "inventory") {
      const value =
        e.target.value === ""
          ? inventory
          : type === "add"
          ? parseInt(e.target.value) + inventory
          : inventory - parseInt(e.target.value);
      setNewStock(value);

      setSendData({ ...sendData, [e.target.name]: value });
    } else {
      setSendData({ ...sendData, [e.target.name]: e.target.value });
    }
  };

  const sendDataFunc = async () => {
    try {
      const host = process.env.REACT_APP_URL;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(sendData),
      };
      const response = await fetch(
        `${host}/api/inventory/updateInventory/${data.attr}`,
        requestOptions
      );
      const datares = await response.json();
      if (datares.status === "Success") {
        alert(datares.msg);
        open();
      } else {
      }
    } catch (error) {
      console.log("error - ", error);
    }
  };

  return (
    <>
      <div
        className="modal"
        style={{ display: "block", background: "#00000080" }}
      >
        <div className="modal-dialog" style={{ width: "30em" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{type} Inventory</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={open}
              ></button>
            </div>
            <div className="modal-body " style={{ borderTop: "1px dashed" }}>
              <div className="form-group">
                <label htmlFor="p_name" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  className="form-control   m-0"
                  name="date"
                  id="date"
                  aria-describedby="helpId"
                  onChange={onChangeData}
                />
              </div>

              <div
                className="d-flex justify-content-between my-3"
                style={{ background: "#7272712b" }}
              >
                <span>Current Stock</span>
                <span className="font-bold">
                  {data.attr === "noattr"
                    ? data.inventory
                    : data.p_price.find((pro) => pro._id === data.attr_id)
                        .inventory}
                </span>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="p_name" className="form-label">
                  Enter Quantity to {type === "add" ? "increase" : "reduce"}
                </label>
                <input
                  type="number"
                  className="form-control   m-0"
                  name="inventory"
                  id="inventory"
                  aria-describedby="helpId"
                  placeholder={`Quantity to ${data.type}`}
                  onChange={onChangeData}
                />
              </div>

              <div
                className="d-flex justify-content-between my-3"
                style={{
                  background: "#7272712b",
                  color: `${data.type === "add" ? "green" : "red"}`,
                }}
              >
                <span>Updated Stock</span>
                <span className="" style={{ fontWeight: "bold" }}>
                  {newStock}
                </span>
              </div>

              <div className="d-flex">
                <button className="cancel_btn me-2 w-50" onClick={open}>
                  Cancel
                </button>
                <button
                  className="btn btn-primary w-50 orange-btn me-2"
                  onClick={sendDataFunc}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
