import React from "react";
import logo from "../../mylogo.png";

import { Link, NavLink, useLocation } from "react-router-dom";

export default function AdminNavBar() {
  let location = useLocation();
  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="app-brand demo">
          <Link to="/" className="app-brand-link">
            <span className="app-brand-logo demo">
              <img src={logo} alt="logo" width="170" />
            </span>
            {/* <span className="app-brand-text demo menu-text fw-bolder ms-2">
              MYORDERSLIP
            </span> */}
          </Link>

          <Link
            to="/"
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="bx bx-chevron-left bx-sm align-middle"></i>
          </Link>
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <NavLink
            to="/admin"
            className={`menu-item ${location.pathname === "/admin" ? "active" : ""}`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-home-circle"></i>
              <div data-i18n="Analytics">Dashboard</div>
            </li>
          </NavLink>

          <NavLink
            to="admin/user-list"
            className={`menu-item ${
              location.pathname === "/admin/user-list" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-layout"></i>
              <div data-i18n="Layouts">User List</div>
            </li>
          </NavLink>

          

          <NavLink
            to="/customer-order"
            className={`menu-item ${
              location.pathname === "/orders" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-file"></i>
              <div data-i18n="Layouts">Subscription Plans</div>
            </li>
          </NavLink>

          

          {/* <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Sales</span>
          </li> */}

          

          

         
        </ul>
      </aside>
      {/* <div className="layout-overlay layout-menu-toggle"></div> */}
    </>
  );
}
