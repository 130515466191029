import React from "react";
import "./nodata.css";
const Nodata = ({ data_text }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh", backgroundColor: "transparent" }}
    >
      <div className="container text-center" style={{ maxWidth: "600px" }}>
        <img
          src="./assets/img/illustrations/notfound.png"
          className="img-fluid"
          alt="No Data"
        />
        <h1 className="nodataheading mt-3">There Are No {data_text}</h1>
      </div>
    </div>
  );
};

export default Nodata;
