import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Noticontext from "../context/notification/NotifContext";
import "../css/orderDetails.css";
import { CartButton } from "../componant/MyButton";
import Add_more_product from "./order_edit/Add_more_product";
import OrderContext from "../context/Order/OrderCon";

// require("dotenv").config();
export default function OrderDetails(props) {
  const { type, id } = useParams();
  const [orderDetailsData, setOrderData] = useState({});
  const [orderlist, setorderlist] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [companyData, setCompanyData] = useState({});
  let grandTotal = 0;
  const notiContext = useContext(Noticontext);
  const orderContext = useContext(OrderContext);

  const { orderStatusChange } = orderContext;
  const { pushNotification } = notiContext;
  const host = process.env.REACT_APP_URL;
  const [edit_body, setEditBody] = useState({ total_price: 0, order_list: [] });

  const [editOrder, setEditOrder] = useState(false);
  // add product variable
  const [showAddMoreProduct, setShowAddMoreProducst] = useState(false);
  const [selectedSupplier_id, set_selectedSupplier_id] = useState("");

  const getData = async (id) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/order/getOrderDetails/${id}`,
        requestOptions
      );
      const datares = await response.json();

      setOrderData(datares.data);
      setorderlist(datares.data.order_list);
      setCustomerData(datares.data.userid);

      setEditBody({
        ...edit_body,
        order_list: datares.data.order_list,
        total_price: datares.data.total_price,
        order_id: datares.data._id,
      });

      status_key = status.indexOf(datares.data.order_status);
      if (status_key < 4) {
        setOrderStatus(status[status_key + 1]);
      } else if (status_key === 4) {
        setOrderStatus(status[status_key]);
      }
      getCompany(datares.data.userid._id);
    } catch (err) {}
  };

  const getCompany = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${host}/api/company/getCompanyById/${id}`,
      requestOptions
    );
    const datares = await response.json();
    console.log(datares);
    setCompanyData(datares.data);
  };

  useEffect(() => {
    getData(id);
  }, []);

  const date = orderDetailsData.createdAt;
  const timePickerMomentObj = new Date(date);
  const day = timePickerMomentObj.getDate();
  const month = timePickerMomentObj.getMonth() + 1;
  const fullyear = timePickerMomentObj.getFullYear();

  const changeStatus = async (orderid, statusType, or_id) => {
    orderStatusChange(orderid, statusType, or_id, (status, datares) => {
      if (status === "success") {
        props.showAlert(datares.msg, "success");
        pushNotification(
          datares.data.userid,
          "order",
          "Change Order Status",
          datares.msg
        );
        // getAllOrders();

        getData(id);
        props.showAlert(datares.msg, "success");
        // pushNotification(
        //   datares.data.userid,
        //   "order",
        //   "Change Order Status",
        //   datares.msg
        // );

        if (statusType === "delivered") {
          const today = new Date();
          const date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

          addPayment({
            supplier_id: datares.data.supplier_id,
            amt: -datares.data.total_price,
            collectedBy: "Order",
            mode: "Online",
            desc: "Order Has Been Delivered",
            createBy: "user",
            createAt: date,
          });
        }
      } else {
        props.showAlert(datares.msg, "danger");
      }
    });

    // const requestOptions = {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "auth-token": localStorage.getItem("token"),
    //   },
    // };
    // const response = await fetch(
    //   `${host}/api/order/changeOrderStatus/${orderid}/${statusType}`,
    //   requestOptions
    // );
    // const datares = await response.json();
    // if (datares.status === "Success") {
    //   getData(id);
    //   props.showAlert(datares.msg, "success");
    //   pushNotification(
    //     datares.data.userid,
    //     "order",
    //     "Change Order Status",
    //     datares.msg
    //   );

    //   if (statusType === "delivered") {
    //     const today = new Date();
    //     const date =
    //       today.getFullYear() +
    //       "-" +
    //       (today.getMonth() + 1) +
    //       "-" +
    //       today.getDate();

    //     addPayment({
    //       supplier_id: datares.data.supplier_id,
    //       amt: -datares.data.total_price,
    //       collectedBy: "Order",
    //       mode: "Online",
    //       desc: "Order Has Been Delivered",
    //       createBy: "user",
    //       createAt: date,
    //     });
    //   }
    // } else {
    //   props.showAlert(datares.msg, "danger");
    // }
  };

  // add payments data

  const addPayment = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${host}/api/payment/createPayment/orderComplete`,
      requestOptions
    );
    const datares = await response.json();
  };

  const status = ["pending", "confirmed", "packed", "dispatched", "delivered"];
  let status_key = 0;

  const [orderstatus, setOrderStatus] = useState("");
  const updateOrderList = (type, total, position, price) => {
    let total_p = parseFloat(edit_body.total_price);

    let data = [];

    if (type === "removing") {
    } else if (type === "min") {
      data = edit_body.order_list.map((pro, index) => {
        return index === position
          ? { ...pro, qty: parseInt(edit_body.order_list[position].qty) - 1 }
          : pro;
      });

      total_p = total_p - parseFloat(price);
    } else if (type === "plus") {
      data = edit_body.order_list.map((pro, index) => {
        return index === position
          ? { ...pro, qty: parseInt(edit_body.order_list[position].qty) + 1 }
          : pro;
      });
      total_p = total_p + parseFloat(price);
    }

    setEditBody({ ...edit_body, order_list: data, total_price: total_p });

    setEditOrder(true);
  };

  const updateOrderDataToServer = async () => {
    console.log("update order dat - ", edit_body);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(edit_body),
    };
    const response = await fetch(
      `${host}/api/order/updateOrder`,
      requestOptions
    );
    const datares = await response.json();

    props.showPreloader("");
    setEditOrder(false);
  };

  return (
    <>
      {showAddMoreProduct && (
        <>
          <Add_more_product
            open={() => {
              setShowAddMoreProducst(false);
            }}
            id={selectedSupplier_id}
            type={type}
            addProductCallBack={(object) => {
              console.log("new object - ", object);
              setEditOrder(true);

              const match = edit_body.order_list.some(
                (pro) => pro.product_id._id === object.product_id._id
              );

              if (match) {
                setEditBody((prevState) => ({
                  ...prevState,
                  total_price:
                    parseFloat(prevState.total_price) + object.total_price,
                  order_list: edit_body.order_list.map((pro) => {
                    return pro.product_id._id === object.product_id._id
                      ? { ...pro, qty: pro.qty + object.qty }
                      : pro;
                  }),
                }));
              } else {
                setEditBody((prevState) => ({
                  ...prevState,
                  total_price:
                    parseFloat(prevState.total_price) + object.total_price,
                  order_list: [...prevState.order_list, object],
                }));
              }
            }}
          />
        </>
      )}
      <div className="content-wrapper orderDetailSection">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div
            className="row p-2 m-0 align-vertical"
            style={{
              background: "white",
              borderRadius: "10px",
              border: "1px solid #7272719e",
            }}
          >
            <div className="col-6">
              <p className="p-0 m-0 orderHeading">
                Order{" "}
                <span className="orderId">#{orderDetailsData.order_id}</span>{" "}
              </p>
              <small className="order_date text-muted">
                Date : {day + "/" + month + "/" + fullyear}
              </small>
            </div>

            <div className="col-6 text-right my-auto">
              {/* <button className="btn  me-2 my_white_button orderBtn my-auto">
                {" "}
                <i class="bx bx-edit-alt"></i> Edit
              </button> */}
              <button className="btn  me-2 my_white_button orderBtn my-auto">
                <i class="bx bx-printer"></i> Print
              </button>
              <button className="btn  me-2 my_white_button orderBtn my-auto">
                <i class="bx bxs-file"></i> Convert to Invoice
              </button>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-9 mb-4 ">
              <div className="card mb-4 ">
                <h5 className="card-header mb-0 pb-0">
                  <div className="row align-items-center">
                    <div className="col-lg-6 orderHeading">
                      Ordered Items ({edit_body.order_list.length})
                    </div>
                    <div
                      className="col-lg-6 text-right"
                      style={{ color: "#ef6817" }}
                    >
                      {/* add more item btn */}
                      {orderDetailsData.order_status !== "delivered" && (
                        <span
                          className="small"
                          onClick={() => {
                            setShowAddMoreProducst(true);
                            if (type === "pts") {
                              set_selectedSupplier_id(
                                orderDetailsData.userid._id
                              );
                            } else {
                              set_selectedSupplier_id(
                                orderDetailsData.supplier_id._id
                              );
                            }
                          }}
                        >
                          + Add more items
                        </span>
                      )}
                      {/* add more item btn */}
                    </div>
                  </div>
                  <hr className="mb-0 pb-0" />
                </h5>
                {/* table start here */}
                <div className="card-body">
                  <div className="table-responsive text-nowrap ">
                    <table className="table table-hover orderDetailTable">
                      <tbody className="table-border-bottom-0 order_history_body order_details">
                        {edit_body.order_list.map((pro, index) => {
                          let price =
                            pro.product_id.p_price.length > 0
                              ? parseFloat(
                                  pro.product_id.p_price.filter(
                                    (item) => item._id === pro.attr_id
                                  )[0].value
                                )
                              : pro.price;

                          let mrp = price;
                          let unitType = pro.product_id.unit_name;

                          let salePrice = pro.catlog_id.flattDiscount
                            ? price - pro.catlog_id.discount
                            : (price * (100 - pro.catlog_id.discount)) / 100;

                          if (pro.sp_rate !== "null") {
                            let sp_data = "";
                            try {
                              sp_data = JSON.parse(pro.sp_rate);
                            } catch (err) {
                              sp_data = pro.sp_rate;
                            }

                            salePrice =
                              sp_data.flattDiscount === true
                                ? price - sp_data.discount
                                : (price * (100 - sp_data.discount)) / 100;
                          }

                          if (pro.type === "mpkg") {
                            price =
                              price * pro.product_id.mpkg * pro.product_id.pkg;
                            salePrice =
                              salePrice *
                              pro.product_id.mpkg *
                              pro.product_id.pkg;
                            unitType = pro.product_id.mpkg_unit;
                          } else if (pro.type === "pkg") {
                            price = price * pro.product_id.pkg;
                            salePrice = salePrice * pro.product_id.pkg;
                            unitType = pro.product_id.pkg_unit;
                          } else {
                            price = price;
                            salePrice = salePrice;
                          }

                          const total = parseFloat(salePrice * pro.qty).toFixed(
                            2
                          );
                          grandTotal = grandTotal + parseFloat(total);

                          return (
                            <>
                              <tr key={index}>
                                <td className="orderd_image_containe">
                                  <img
                                    src={
                                      pro.product_id.p_gallery_image.length > 0
                                        ? pro.product_id.p_gallery_image[0].link
                                        : ""
                                    }
                                    alt="Product Image"
                                    className="img-fluid"
                                  />
                                </td>
                                <td>
                                  <p className="o_d_title">
                                    {pro.product_id.p_name}
                                  </p>
                                  <span className="o_d_vari">
                                    {" "}
                                    {pro.product_id.p_price.length === 0
                                      ? "No Variation"
                                      : pro.product_id.p_price.map((p) => {
                                          return p.value === pro.price
                                            ? p.name
                                            : "";
                                        })}
                                  </span>
                                  {/* <small className="o_d_sku">
                                    SKU : {pro.product_id.p_sku}
                                  </small> */}
                                  <small className="o_d_sku">
                                    MRP : <strike>₹{price.toFixed(2)}</strike>
                                  </small>
                                </td>
                                <td className="o_d_price_cell">
                                  <small>
                                    ₹{parseFloat(salePrice).toFixed(2)}{" "}
                                  </small>
                                  <span className="o_d_p_cell_value">
                                    / {unitType}
                                  </span>
                                </td>
                                <td>X</td>
                                <td>
                                  <CartButton
                                    btnShow={
                                      orderDetailsData.order_status !==
                                      "delivered"
                                    }
                                    show={true}
                                    value={pro.qty}
                                    callback={(type, value) => {
                                      let newQty;
                                      if (type === "plus") {
                                        newQty = pro.qty + 1;
                                      } else if (type === "min") {
                                        newQty = pro.qty - 1;
                                      }

                                      updateOrderList(
                                        type,
                                        0,
                                        index,
                                        parseFloat(salePrice).toFixed(2)
                                      );
                                      // updateCartQty(pro._id, newQty);

                                      // updateOrderList(
                                      //   pro,
                                      //   newQty,
                                      //   total_price,
                                      //   edit_body,
                                      //   productModals
                                      // );
                                    }}
                                  />
                                  {/* <input
    type="text"
    className="form-control o_d_qty"
    defaultValue={pro.qty}
  /> */}
                                </td>

                                <td className="totalCell">
                                  <span>₹{total}</span>
                                </td>
                              </tr>
                              {index < edit_body.order_list.length - 1 && (
                                <tr>
                                  <td colSpan={6}>
                                    <hr />
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card-footer border-1">
                  <table width={"100%"}>
                    {/* <tr>
                      <td colSpan={6} className="p-0">
                        <hr className="mb-0 pb-0" />
                      </td>
                    </tr> */}
                    <tr>
                      {/* <td className="order_note">Order Note</td>
                      <td className="order_note_content">
                        <small>
                          {orderDetailsData.remark}
                        </small>{" "}
                      </td> */}
                      <td className="order_note">Grand Total</td>
                      <td></td>
                      <td></td>
                      <td className="o_d_title totalCell">
                        ₹{parseFloat(grandTotal).toFixed(2)}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              {orderDetailsData.remark && (
                <div className="card">
                  <div className="card-body">
                    <p className="order_note m-0">Instruction/Remark</p>

                    <p className="order_note_content m-0">
                      <small>{orderDetailsData.remark}</small>{" "}
                    </p>
                  </div>
                </div>
              )}

              <div className="card mt-4">
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-12 orderHeading">
                      Customer Details
                    </div>
                    <div className="col-lg-6 font-bold text-right"></div>
                  </div>
                  <hr className="mb-0 pb-0" />
                </h5>
                <div className="d-flex  row pb-4">
                  <div className="col-sm-4 pl-3 px-2 pb-4 text-center">
                    <img
                      src="../assets/img/avatars/1.png"
                      alt="Customer Image"
                      className=" rounded-circle "
                    />
                  </div>
                  <div className="col-sm-8 ">
                    <div className="card-body p-0 m-0">
                      <h5 className="card-title text-primary companyTitle mb-4">
                        {companyData && companyData.com_name}
                      </h5>
                      <table width={"100%"} className="myCompany">
                        <tr>
                          <td>
                            <small className="muted">Name</small>
                          </td>
                          <td>-</td>
                          <td>{customerData && customerData.fullname}</td>
                        </tr>
                        <tr>
                          <td>
                            <small className="muted">Address</small>
                          </td>
                          <td>-</td>
                          <td>{customerData.address}</td>
                        </tr>

                        <tr>
                          <td>
                            <small className="muted">Phone</small>
                          </td>
                          <td>-</td>
                          <td>{customerData.phone}</td>
                        </tr>

                        {/* <tr>
                          <td>
                            <small className="muted">Email</small>
                          </td>
                          <td>-</td>
                          <td>{orderDetailsData.userid.address}</td>
                        </tr> */}

                        <tr>
                          <td>
                            <small className="muted">GST</small>
                          </td>
                          <td>-</td>
                          <td>{companyData && companyData.gst_no}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* table layout */}

            <div className="col-lg-3 mb-4">
              <div className="card">
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-12 orderHeading">Order Journey</div>
                    <div className="col-lg-6 font-bold text-right"></div>
                  </div>
                  <hr className="mb-0 pb-0" />
                </h5>
                <div className="card-body">
                  {editOrder ? (
                    <div className="orderStatusbtn d-flex justify-content-center">
                      <div
                        className="btn   cancel_btn  me-2"
                        style={{ width: "fit-content" }}
                        onClick={() => {
                          // setEditOrder(false);
                          console.log(
                            "order dat - ",
                            orderDetailsData.order_list
                          );
                          setEditBody({
                            total_price: 0,
                            order_list: orderDetailsData.order_list,
                          });
                        }}
                      >
                        Cancel
                      </div>
                      <div
                        className="btn  my_white_button orderBtn "
                        onClick={() => {
                          props.showCon(
                            "Are you sure you want to edit the order ?",
                            true,
                            async () => {
                              // deleteCategory(c._id);
                              props.showPreloader("preShow");
                              props.showCon("", false, {});

                              updateOrderDataToServer();
                            }
                          );
                        }}
                      >
                        Save
                      </div>
                    </div>
                  ) : (
                    <div className="orderStatusbtn ">
                      {orderDetailsData.order_status === "pending" ? (
                        <>
                          <div
                            className="btn btn-sm  reject btn-outline-primary"
                            onClick={() => {
                              changeStatus(
                                orderDetailsData.order_id,
                                "reject",
                                orderDetailsData._id
                              );
                            }}
                          >
                            Reject
                          </div>
                          <div
                            className="btn btn-sm accept btn-outline-primary"
                            onClick={() => {
                              changeStatus(
                                orderDetailsData.order_id,
                                "confirmed",
                                orderDetailsData._id
                              );
                            }}
                          >
                            Accept
                          </div>
                        </>
                      ) : (
                        <div
                          className={`${
                            orderDetailsData.order_status !== "delivered" &&
                            "btn my_white_button orderBtn"
                          }   w-100`}
                          onClick={() => {
                            if (orderDetailsData.order_status === "delivered") {
                              props.showAlert(
                                "Journey has been completed",
                                "success"
                              );
                            } else {
                              changeStatus(
                                orderDetailsData.order_id,
                                orderstatus,
                                orderDetailsData._id
                              );
                            }
                          }}
                        >
                          {orderDetailsData.order_status === "delivered"
                            ? "Order Journey has been completed."
                            : orderstatus.toString().toUpperCase()}
                          {/* {orderDetailsData.order_status && orderDetailsData.order_status.toString().toUpperCase()} */}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <ul className="o_d_journey">
                  {orderDetailsData.order_status === "cancelled" && (
                    <li>
                      <span
                        className={`badge bg-label-danger rounded-pill ${
                          orderDetailsData.order_status === "cancelled" &&
                          "active"
                        }`}
                      >
                        {/* <img
                          src={orderconfirm}
                          className="img-fluid"
                          width={30}
                        /> */}
                      </span>
                      <span className="j_text">Cancelled</span>
                    </li>
                  )}

                  {orderDetailsData.order_status === "rejected" && (
                    <li>
                      <span
                        className={`badge bg-label-danger rounded-pill ${
                          orderDetailsData.order_status === "rejected" &&
                          "active"
                        }`}
                      >
                        {/* <img
                          src={orderconfirm}
                          className="img-fluid"
                          width={30}
                        /> */}
                      </span>
                      <span className="j_text">Rejected</span>
                    </li>
                  )}

                  <li>
                    <span
                      className={`badge bg-label-danger rounded-pill ${
                        (orderDetailsData.order_status === "confirmed" ||
                          orderDetailsData.order_status === "packed" ||
                          orderDetailsData.order_status === "dispatched" ||
                          orderDetailsData.order_status === "delivered") &&
                        "active"
                      }`}
                    >
                      {/* <img
                        src={orderconfirm}
                        className="img-fluid"
                        width={30}
                      /> */}
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </span>
                    <span className="j_text">In Process</span>
                  </li>

                  <li>
                    <span
                      className={`badge bg-label-danger rounded-pill ${
                        (orderDetailsData.order_status === "packed" ||
                          orderDetailsData.order_status === "dispatched" ||
                          orderDetailsData.order_status === "delivered") &&
                        "active"
                      }`}
                    >
                      <i class="bx bxs-package"></i>
                    </span>
                    <span className="j_text">Packed</span>
                  </li>

                  <li>
                    <span
                      className={`badge bg-label-danger rounded-pill ${
                        (orderDetailsData.order_status === "dispatched" ||
                          orderDetailsData.order_status === "delivered") &&
                        "active"
                      }`}
                    >
                      <i class="bx bxs-truck"></i>
                    </span>
                    <span className="j_text">Dispatched</span>
                  </li>

                  <li>
                    <span
                      className={`badge bg-label-danger rounded-pill ${
                        orderDetailsData.order_status === "delivered" &&
                        "active"
                      }`}
                    >
                      <i class="bx bx-check"></i>
                    </span>
                    <span className="j_text">Delivered</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-9 mb-4"></div>
          </div>
        </div>
      </div>
    </>
  );
}
