import React, { useState } from "react";
import CatalogContext from "./CatalogContext";

//require("dotenv").config();

const CatalogState = (props) => {
  const host = process.env.REACT_APP_URL;

  const cataloges = [
    {
      _id: "",
      userid: "",
      c_id: "",
      catalog_name: "",
      flattDiscount: true,
      discount: 0,
      products: [],
      customers:[],
      createdAt: "",
      __v: 0,
    },
  ];

  const [catalog, setcatalog] = useState(cataloges);

  const getAllCatalog = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/catalog/getCatalog/${id}`,
      requestOptions
    );
    const datares = await response.json();

    //console.log(datares.data);
    setcatalog(datares.data);
  };

  const AddNewCatalogData = async (data) => {
    try {
      const bodydata = data;
      console.log(bodydata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/catalog/createCatalog`,
        requestOptions
      );
      const datares = await response.json();
      if (datares.status === "Failed") {
      } else {
        const comp = datares.data;
        setcatalog(catalog.concat(comp));
      }
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  // delet catalog http://localhost:4000/api/catalog/deleteCatalog/
  const deleteCatalog = async (id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const url = `${host}/api/catalog/deleteCatalog/` + id;
      const response = await fetch(url, requestOptions);
      const datares = await response.json();

      if (datares.status === "Success") {
        const newCatalog = catalog.filter((com) => {
          return com._id !== id;
        });
        setcatalog(newCatalog);
      } else {
        alert("Something wentwrong");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // update catelog data
  const updateCatalog = async(data, id)=>{    
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
      };
      const url = `${host}/api/catalog/updateCatalog/` + id;      
      const response = await fetch(url, requestOptions);
      const datares = await response.json();
      let newCatalogupdate = JSON.parse(JSON.stringify(catalog));
      console.log(datares);
      for (let index = 0; index < newCatalogupdate.length; index++) {
        const element = newCatalogupdate[index];
        if (element._id === datares.data._id) {
          newCatalogupdate[index] = datares.data;
          break;
        }
      }
      setcatalog(newCatalogupdate);
      
      return datares.status
    } catch (e) {
      console.log(e.message);
    }
  }


  const updateCatalogName = async(data, id)=>{    
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
      };
      const url = `${host}/api/catalog/updateCatalogName/` + id;      
      const response = await fetch(url, requestOptions);
      const datares = await response.json();
      if(datares.status === "Success"){
        let newCatalogupdate = JSON.parse(JSON.stringify(catalog));
        console.log(datares);
        for (let index = 0; index < newCatalogupdate.length; index++) {
          const element = newCatalogupdate[index];
          if (element._id === datares.data._id) {
            newCatalogupdate[index] = datares.data;
            break;
          }
        }
        setcatalog(newCatalogupdate);
      }
      
      
      return datares
    } catch (e) {
      console.log(e.message);
    }
  }


  const updateProductCatalog = async(data, id)=>{    
    console.log(data);
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
      };
      const url = `${host}/api/catalog/updateProductCatalog/` + id;      
      const response = await fetch(url, requestOptions);
      const datares = await response.json();
      let newCatalogupdate = JSON.parse(JSON.stringify(catalog));
      console.log(datares);
      if(datares.status === "Sucess"){
        for (let index = 0; index < newCatalogupdate.length; index++) {
          const element = newCatalogupdate[index];
          if (element._id === datares.data._id) {
            newCatalogupdate[index] = datares.data;
            break;
          }
        }
        setcatalog(newCatalogupdate);
      }
      
      return datares.status
    } catch (e) {
      console.log(e.message);
    }
  }


  // customer api
  const [supplierCatalog, setSupplierCatalog] = useState([]);
  const getCustomerCatalog = async(id)=>{

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": localStorage.getItem("token"),
      },
    };
    const url = `${host}/api/customer/product/getAllCatalog/` + id;      
    const response = await fetch(
      url,
      requestOptions
    );
    const datares = await response.json();

   
    //console.log(datares.data);
    setSupplierCatalog(datares.data);

    

  }
  

  return (
    <CatalogContext.Provider
      value={{
        catalog,
        getAllCatalog,
        AddNewCatalogData,
        deleteCatalog,
        updateCatalog,
        updateProductCatalog,
        updateCatalogName,


        supplierCatalog,
        getCustomerCatalog
      }}
    >
      {props.children}
    </CatalogContext.Provider>
  );
};
export default CatalogState;
