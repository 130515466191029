import "./App.css";
import React, { useState, useEffect } from "react";

import Navbar from "./section/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Topbar from "./section/TopBar";

import Order from "./pages/Order";
import Catelog from "./pages/Catelog";
import Products from "./pages/Product";
import { Customer } from "./pages/Customer";
import Supplier from "./pages/Supplier";
import ContentWrapper from "./section/contentWraper";
import AddnewCompany from "./pages/AddNewCompany";
import CustomerDetails from "./pages/CustomerDetails";
import Payment from "./pages/Payment";
import Login from "./pages/Login";
import SupplierDetails from "./pages/suplier_details";

import MyCompany from "./pages/Mycompany";
import CompanyState from "./context/Company/CompanyState";
import Modal from "./componant/Modal";
import EditCompany from "./pages/EditCompany";
import Alert from "./componant/Alert";
import CustomerState from "./context/Customer/CustomerState";
import AddNewProduct from "./pages/AddNewProduct";
import { Category } from "./pages/Category";
import Attribute from "./pages/Attribute";
import CatState from "./context/Category/CategoryState";
import ProductState from "./context/Product/ProductState";
import CatalogState from "./context/Catalog/CatalogState";
import Step from "./pages/Step";
import { StaffData } from "./pages/Staff";
import StaffState from "./context/Staff/StaffState";
import RolesPermission from "./pages/RolesPermission";
import RawMaterial from "./pages/RawMaterial";
import ConfirmAlert from "./componant/ConfirmAlert/ConfirmAlert";
import CustomerNavData from "./section/customerLogin/CustomerNavbar";
import Subscribe from "./pages/Subscribe";
import Customer_supplier from "./pages/CustomerPages/Customer-supplier";
import Customer_payment from "./pages/CustomerPages/Customer-payments";
import Customer_orders from "./pages/CustomerPages/Customer-orders";
import Createorder from "./pages/CustomerPages/CreateOrder";
import MyCart from "./pages/CustomerPages/CartPage";
import SupplierState from "./context/GetSupplier/SupplierState";
import Reports from "./pages/Reports";
import NotiState from "./context/notification/NotiState";
import OrderDetails from "./pages/OrderDetails";
import ReportState from "./context/Report/ReportState";
import PrivacyPolicy from "./pages/privacy_policy";
import Invoice from "./pages/invoice";
import { CreateInvoice } from "./componant/InvoiceComponant/CreateNew";
import SettingState from "./context/Setting/SettingState";
import InvoiceDetails from "./componant/InvoiceComponant/InvoiceDetails";
import Redirect from "./componant/Redirect";
import Upgrade_App from "./pages/upgrade_app/Upgrade_App";
import PaymentGateway from "./context/payments/PaymentGatewayState";
import OrderState from "./context/Order/OrderState";
import CreateOrder from "./pages/CustomerPages/CreateOrder";
import CartManager from "./context/cart/CartManager";

import Inventory from "./pages/Inventory1";
import Preloader from "./componant/Preloader";
import AdminLayout from "./adminPage/Admin_layout";
import UserLayout from "./UserLayout";
import UserList from "./adminPage/UserList";
import AdminNavBar from "./section/customerLogin/AdminNavbar";
import AdminState from "./context/admin/AdminState";
import AdminDashboard from "./adminPage/AdminDashboard";
function App() {
  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
      active: "alert-active",
    });

    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const [conAlert, setConAlert] = useState({
    heading: "Text content",
    open: false,
    confirm: {},
  });

  const showCon = (heading, show, confirm) => {
    setConAlert({
      heading: heading,
      open: show,
      confirm: confirm,
    });
  };

  const [preControl, setPreControl] = useState("");

  const showPreloader = (type) => {
    setPreControl(type);
  };

  // localStorage.setItem("user_data",JSON.stringify({type:"supplier"}) );
  // localStorage.clear();
  const userType = JSON.parse(localStorage.getItem("user_data"));
  const [navbar, setNavbar] = useState(true);
  useEffect(() => {
    if (userType !== null && userType.type === "supplier") {
      setNavbar(!navbar);
      return;
    }
  }, []);

  const primary_c_id = localStorage.getItem("com_id");

  const chooseCom = () => {
    if (localStorage.getItem("com_id") === null) {
      return <Navigate to="/mycompanies" />;
    }
  };

  const params = window.location.pathname;

  return (
    <>
      <AdminState>
        <CartManager>
          <OrderState>
            <CompanyState>
              <CustomerState>
                <CatState>
                  <ProductState>
                    <CatalogState>
                      <StaffState>
                        <SupplierState>
                          <NotiState>
                            <ReportState>
                              <SettingState>
                                <PaymentGateway>
                                  <Router>
                                    <Alert alert={alert} />
                                    <ConfirmAlert
                                      conAlert={conAlert}
                                      showCon={showCon}
                                    />
                                    <Preloader show={preControl} />
                                    <div className="layout-wrapper layout-content-navbar">
                                      <div className="layout-container">
                                        {params.includes("admin") ? (
                                          <AdminNavBar />
                                        ) : navbar ? (
                                          <CustomerNavData />
                                        ) : (
                                          <Navbar />
                                        )}
                                        <Modal />
                                        <div className="layout-page">
                                          <Topbar />
                                          <Routes>
                                            <Route
                                              path="/admin"
                                              element={<AdminLayout />}
                                            >
                                              <Route
                                                path=""
                                                element={<AdminDashboard />}
                                              />
                                              <Route
                                                path="user-list"
                                                element={<UserList />}
                                              />
                                            </Route>

                                            <Route
                                              path="/app"
                                              element={<Redirect />}
                                            />

                                            <Route
                                              path="/upgrade_app/:id"
                                              element={
                                                <Upgrade_App
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/*"
                                              element={<Navigate to="/" />}
                                            />

                                            <Route
                                              path="/"
                                              element={
                                                <ContentWrapper
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/mycompanies"
                                              element={
                                                <MyCompany
                                                  showCon={showCon}
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/orders"
                                              element={
                                                <Order
                                                  showAlert={showAlert}
                                                  chooseCom={chooseCom}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/ordersDetails/:type/:id"
                                              element={
                                                <OrderDetails
                                                  showAlert={showAlert}
                                                  showCon={showCon}
                                                  showPreloader={showPreloader}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/catelog"
                                              element={
                                                <Catelog
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/step"
                                              element={<Step />}
                                            />
                                            <Route
                                              path="/rawMaterial"
                                              element={
                                                <RawMaterial
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/products"
                                              element={
                                                <Products
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/inventory"
                                              element={
                                                <Inventory
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/addNewProduct"
                                              element={
                                                <AddNewProduct
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/category"
                                              element={
                                                <Category
                                                  showAlert={showAlert}
                                                  showCon={showCon}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/attr"
                                              element={
                                                <Attribute
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/customers"
                                              element={
                                                <Customer
                                                  showAlert={showAlert}
                                                  showCon={showCon}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/suppliers"
                                              element={<Supplier />}
                                            />
                                            <Route
                                              path="/payments"
                                              element={
                                                <Payment
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/addnewCompany"
                                              element={
                                                <AddnewCompany
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/EditCompany"
                                              element={<EditCompany />}
                                            />

                                            <Route
                                              path="/customerDetails"
                                              element={<CustomerDetails />}
                                            />
                                            <Route
                                              path="/suplierDetails"
                                              element={<SupplierDetails />}
                                            />
                                            <Route
                                              path="/login"
                                              element={
                                                <Login showAlert={showAlert} />
                                              }
                                            />

                                            <Route
                                              path="/emplyoee"
                                              element={
                                                <StaffData
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/roles-permission"
                                              element={
                                                <RolesPermission
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/reports"
                                              element={
                                                <Reports
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/privacy"
                                              element={
                                                <PrivacyPolicy
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/invoice"
                                              element={
                                                <Invoice
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/createInvoice"
                                              element={
                                                <CreateInvoice
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/invoice/invoiceDetails/:id"
                                              element={
                                                <InvoiceDetails
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            {/* customer control */}

                                            <Route
                                              path="/customer-order"
                                              element={
                                                <Customer_orders
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/createorder/:type/:id"
                                              element={
                                                <CreateOrder
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/customer-supplier"
                                              element={
                                                <Customer_supplier
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/customer-payments"
                                              element={
                                                <Customer_payment
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/mycart"
                                              element={
                                                <MyCart showAlert={showAlert} />
                                              }
                                            />

                                            <Route
                                              path="/subscribe"
                                              element={
                                                <Subscribe
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />

                                            <Route
                                              path="/subscribe/:id"
                                              element={
                                                <Subscribe
                                                  showAlert={showAlert}
                                                />
                                              }
                                            />
                                          </Routes>
                                        </div>
                                      </div>
                                      <div className="layout-overlay layout-menu-toggle"></div>
                                    </div>
                                  </Router>
                                </PaymentGateway>
                              </SettingState>
                            </ReportState>
                          </NotiState>
                        </SupplierState>
                      </StaffState>
                    </CatalogState>
                  </ProductState>
                </CatState>
              </CustomerState>
            </CompanyState>
          </OrderState>
        </CartManager>
      </AdminState>
    </>
  );
}

export default App;
