import React, { useContext, useEffect, useState, useRef } from "react";
import catContext from "../context/Category/catContext";
import productContext from "../context/Product/productContext";
import { useNavigate } from "react-router-dom";
import Preloader from "../componant/Preloader";
export default function AddNewProduct(props) {
  let history = useNavigate();
  const mycontext = useContext(catContext);
  const proContext = useContext(productContext);
  const { category, getAllCategory } = mycontext;
  const { addProduct, updateProduct } = proContext;

  //console.log(attribute);
  useEffect(() => {
    getAllCategory();
  }, []);

  const [subCategory, setSubCategory] = useState([
    {
      name: "Select Category",
    },
  ]);
  const [attribute, setAttribute] = useState([
    {
      id: 0,
      name: "",
      value: "",
      inventory: "",
      p_barcode: "",
      p_sku: "",
      location: ""
    },
  ]);

  const addnewAttribute = (e) => {
    e.preventDefault();
    const value = {
      id: attribute.length,
      name: "",
      value: "",
      inventory: "",
      p_barcode: "",
      p_sku: "",
      location: ""

    };
    setAttribute(attribute.concat(value));
    console.log(attribute);
  };

  const removeAttribute = (mid) => {
    const newAttribute = attribute.filter((com) => {
      return com.id !== mid;
    });
    setAttribute(newAttribute);
  };

  const updateAttr = (mid, e) => {
    console.log(mid);
    console.log(e.target.value);
    for (let i = 0; i < attribute.length; i++) {
      const element = attribute[i];
      if (element.id === mid) {
        if (e.target.name === "attr_name") {
          element.name = e.target.value;
        } else if (e.target.name === "attr_price") {
          element.value = e.target.value;
        } else if (e.target.name === "inventory") {
          element.inventory = e.target.value;
        } else if (e.target.name === "p_sku") {
          element.p_sku = e.target.value;
        } else if (e.target.name === "p_barcode") {
          element.p_barcode = e.target.value;
        }
      }
    }
  };

  const clickChange = (e) => {
    const id = e.target.value;
    setNewProduct({ ...newProduct, cat_id: e.target.value });
    for (let i = 0; i < category.length; i++) {
      const element = category[i];
      if (element._id === id) {
        setSubCategory(element.sub_items);
      }
    }
  };

  // open file field
  const inputFile = useRef(null);
  const openField = () => {
    inputFile.current.click();
  };
  const selectHandler = (e) => {
    e.preventDefault();
    let images = [];
    console.log(e.target.files);
    for (let index = 0; index < e.target.files.length; index++) {
      const element = e.target.files[index];

      images.push(element);
      if (myfiles.files.length === 6 || (myfiles.files.length + images.length) === 6) {
        break;
      }
    }

    console.log("Image dat");
    console.log(images);
    setfiles({ files: [...myfiles.files, ...images] });
    if (myfiles.files.length <= 6) {

      /* setfiles({ files: [...myfiles.files, ...e.target.files] }); */
    }

  };

  const removeSelectedFile = (name) => {
    const files = myfiles.files.filter((data) => {
      return data.name !== name;
    });
    setfiles({ files: files });
  }

  const [myfiles, setfiles] = useState({
    files: [],
  });

  // new product state write is here
  const [newProduct, setNewProduct] = useState({
    c_id: localStorage.getItem("com_id"),
    cat_id: "",
    sub_cat_id: "",
    unit_name: "",
    unit_value: "",
    p_name: "",
    brand: "",
    location: "N/A",
    product_type: "published",
    p_sku: "",
    inventory: "",
    hide_inventory: false,
    visibility: false,
    m_o_q: 1,
    pkg: "",
    pkg_unit: "",
    mpkg: "",
    mpkg_unit: "",
    price: "",
    description: "",
    p_gallery_image: [],
    p_gallery_video: "",
    p_price: [],
    accept_order: "false",
  });

  const mycheckbox = useRef(null);

  const onacceptCheck = (e) => {
    if (e.target.name === "p_unit") {
      mycheckbox.click();
    }
    setNewProduct({ ...newProduct, accept_order: e.target.value });
  };

  const onInputFormData = (e) => {
    e.preventDefault();
    setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
    console.log(e.target.value);
    console.log(e.target.name);
  };


  const handleClick = async (e) => {
    e.preventDefault();
    setpreloader("preShow");
    newProduct.p_price = attribute;
    if (myfiles.files.length === 0) {
      props.showAlert("Please choose Image", "warning");
      inputFile.current.click();
      setpreloader("");
      return;
    }
    const data = new FormData();
    for (let i = 0; i < myfiles.files.length; i++) {
      const element = myfiles.files[i];
      data.append("file", element);
    }
    const result = await addProduct(newProduct);
    if (result.status === "Success") {
      const res = await updateProduct(data, result.data._id);
      if (res.status === "Failed") {
        props.showAlert(res.msg, "danger");
      } else if (res.status === "Sucess") {
        props.showAlert("Image updated", "success");
        history('/products');
      }
    }
    setpreloader("");
    console.log(myfiles.files);

    console.log(result);
    console.log(newProduct);
  };

  const [showpreloader, setpreloader] = useState("");
  return (
    <>
      <Preloader show={showpreloader} />
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <form method="post" onSubmit={handleClick}>
            <div className="row ">
              <h4 className="card-header c_heading">
                <span className="headingcontent">Add New Product</span>
                <div className="heading_buttons"></div>
              </h4>

              <div className="col-12">
                <div className="row addnewproduct">
                  <div className="col-md-6  my-1">
                    <div className="heading">
                      <h6>Product Info</h6>
                    </div>
                    <div className="card  p-0 mb-2">
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="p_name" className="form-label">
                            Product Name
                          </label>
                          <input
                            type="text"
                            className="form-control   m-0"
                            name="p_name"
                            id="p_name"
                            aria-describedby="helpId"
                            placeholder="Product Name"
                            onChange={onInputFormData}
                          />
                        </div>


                        <div className="form-group my-2">
                          <label htmlFor="brand" className="form-label">
                            Brand
                          </label>
                          <input
                            type="text"
                            className="form-control   m-0"
                            name="brand"
                            id="brand"
                            aria-describedby="helpId"
                            placeholder="brand"
                            onChange={onInputFormData}
                          />
                        </div>






                        <div className="form-group my-2">
                          <label htmlFor="description" className="form-label">
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            name="description"
                            id="description"
                            rows="3"
                            placeholder="Description"
                            onChange={onInputFormData}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="heading">
                      <h6>Category</h6>
                    </div>

                    <div className="card mb-2">
                      <div className="card-body pb-0">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlSelect2"
                            className="form-label"
                          >
                            Primary Category
                          </label>
                          <span className="newHyperLink" style={{ fontSize: "15px", marginBottom: "5px" }}>
                            {" "}
                            <i className="bx bx-plus bx-sm align-middle"></i> Add
                            New Category
                          </span>
                          <select
                            className="form-select "
                            id="exampleFormControlSelect2"
                            aria-label="Multiple select example"
                            onChange={clickChange}
                          >
                            <option value="" >Select Category</option>
                            {category.map((c, i) => {
                              return (
                                <option value={c._id} key={i}>
                                  {c.cat_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="sub_cat_id" className="form-label">
                            Secondary Category
                          </label>
                          <span className="newHyperLink" style={{ fontSize: "15px", marginBottom: "5px" }}>
                            {"  "}
                            <i className="bx bx-plus bx-sm align-middle"></i> Add
                            Secondary-Category
                          </span>
                          <select
                            className="form-control  "
                            name="sub_cat_id"
                            id="sub_cat_id"
                            onChange={onInputFormData}
                          >
                            <option value="" >Select Sub Category</option>
                            {subCategory.map((sub, i) => {
                              return (
                                <option value={sub._id} key={i}>
                                  {sub.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="card-footer py-0">


                      </div>
                    </div>

                    <div className="heading">
                      <h6>Inventory</h6>
                    </div>

                    <div className="card mb-2">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group my-2">
                              <label htmlFor="inventory" className="form-label">
                                Inventory
                              </label>
                              <input
                                type="number"
                                className="form-control   m-0"
                                name="inventory"
                                id="inventory"
                                aria-describedby="helpId"
                                placeholder="Quantity"
                                onChange={onInputFormData}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group my-2">
                              <label htmlFor="p_sku" className="form-label">
                                Product SKU
                              </label>
                              <input
                                type="text"
                                className="form-control   m-0"
                                name="p_sku"
                                id="p_sku"
                                aria-describedby="helpId"
                                placeholder="P-100"
                                onChange={onInputFormData}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group my-2">
                              <label htmlFor="p_sku" className="form-label">
                                Product Barcode
                              </label>
                              <input
                                type="text"
                                className="form-control   m-0"
                                name="barcode"
                                id="barcode"
                                aria-describedby="helpId"
                                placeholder="Barcode"
                                onChange={onInputFormData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="heading">
                      <h6>Pricing</h6>
                    </div>

                    <div className="card mb-2">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group my-2">
                              <label htmlFor="price" className="form-label">
                                MRP Of Product
                              </label>
                              <input
                                type="text"
                                className="form-control   m-0"
                                name="price"
                                id="price"
                                aria-describedby="helpId"
                                placeholder="0.0"
                                onChange={onInputFormData}
                              />
                              <small
                                id="helpId"
                                className="form-text text-muted"
                              >
                                Discount on MRP can be made while creating
                                dynamic catalogs or You can fill flat rate of
                                products here.
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group my-2">
                              <label htmlFor="inventory" className="form-label">
                                Unit Value
                              </label>
                              <input
                                type="number"
                                className="form-control m-0"
                                name="unit_value"
                                id="unit_value"
                                aria-describedby="helpId"
                                placeholder="0.0"
                                onChange={onInputFormData}
                              />
                              <small
                                id="helpId"
                                className="form-text text-muted"
                              >
                                Per {newProduct.unit_value}{" "}
                                {newProduct.unit_name}
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group my-2">
                              <label htmlFor="inventory" className="form-label">
                                Product Unit
                              </label>
                              <input
                                type="text"
                                className="form-control m-0"
                                name="unit_name"
                                id="unit"
                                aria-describedby="helpId"
                                placeholder="Unit"
                                onChange={onInputFormData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="heading">
                      <h6>Attributes & Pricing</h6>
                    </div>

                    <div className="card p-0 mb-2">
                      <div className="card-body">
                        {attribute.map((c, i) => {
                          return (
                            <div className="row" key={c.id} id={c.id}>
                              <div className="col-md-4">
                                <div className="form-group my-2">
                                  <label
                                    htmlFor="inventory"
                                    className="form-label"
                                  >
                                    Attribute Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control m-0"
                                    name="attr_name"
                                    id="unit"
                                    aria-describedby="helpId"
                                    placeholder="Ex. - Size, Color, Set etc"
                                    onChange={(e) => {
                                      updateAttr(c.id, e);
                                    }}
                                  />
                                </div>
                                <div className="form-group my-2">
                                  <label
                                    htmlFor="inventory"
                                    className="form-label"
                                  >
                                    Product Sku
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control m-0"
                                    name="p_sku"
                                    id="unit"
                                    aria-describedby="helpId"
                                    placeholder="Enter Sku"
                                    onChange={(e) => {
                                      updateAttr(c.id, e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group my-2">
                                  <label
                                    htmlFor="inventory"
                                    className="form-label"
                                  >
                                    Price
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control m-0"
                                    name="attr_price"
                                    id="unit"
                                    aria-describedby="helpId"
                                    placeholder="Price"
                                    onChange={(e) => {
                                      updateAttr(c.id, e);
                                    }}
                                  />
                                </div>
                                <div className="form-group my-2">
                                  <label
                                    htmlFor="inventory"
                                    className="form-label"
                                  >
                                    Barcode
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control m-0"
                                    name="p_barcode"
                                    id="unit"
                                    aria-describedby="helpId"
                                    placeholder="Barcode"
                                    onChange={(e) => {
                                      updateAttr(c.id, e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group my-2">
                                  <label
                                    htmlFor="inventory"
                                    className="form-label"
                                  >
                                    Inventory
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control m-0"
                                    name="inventory"
                                    id="unit"
                                    aria-describedby="helpId"
                                    placeholder="Inventory"
                                    onChange={(e) => {
                                      updateAttr(c.id, e);
                                    }}
                                  />
                                </div>
                                <div className="form-group my-2">
                                  <label
                                    htmlFor="inventory"
                                    className="form-label"
                                  >
                                    Location
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control m-0"
                                    name="location"
                                    id="unit"
                                    aria-describedby="helpId"
                                    placeholder="Location"
                                    onChange={(e) => {
                                      updateAttr(c.id, e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <span
                                  className="rightbtn text-mute"
                                  onClick={() => {
                                    removeAttribute(c.id);
                                  }}
                                >
                                  <i className="bx bxs-trash-alt"></i> Remove
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="card-footer py-0">
                        <p className="newHyperLink" onClick={addnewAttribute}>
                          {" "}
                          <i className="bx bx-plus bx-sm align-middle"></i> Add
                          New Attribute
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6  my-1">
                    <div className="heading">
                      <h6 className="">Product Images/Videos</h6>
                    </div>

                    <div className="card mb-2">
                      <div className="card-body">
                        <div>
                          <ul className="image_div_ul">
                            <li className="image_div_list " onClick={openField}>
                              <div className="image_div_content">
                                <i className="bx bxs-image-add "></i>
                                <p className="normalhyperlink">
                                  Click to Upload
                                </p>
                              </div>
                            </li>
                            {myfiles.files.length === 0 &&
                              <>
                                <li className="image_div_list">
                                  <div className="image_div_content">
                                    <i className="bx bx-plus "></i>
                                  </div>
                                </li>
                                <li className="image_div_list">
                                  <div className="image_div_content">
                                    <i className="bx bx-plus "></i>
                                  </div>
                                </li>
                              </>
                            }
                            {myfiles.files.map((file, i) => {
                              return (
                                <li className="image_div_list">
                                  <div className="image_div_content">
                                    <div className="action_buttons" onClick={() => { removeSelectedFile(file.name) }}>
                                      <i className="bx bx-trash"></i>
                                    </div>
                                    <img src={URL.createObjectURL(file)} alt="p_image" className="img-fluid" />
                                  </div>
                                </li>
                              )
                            })}


                          </ul>
                        </div>
                      </div>
                      <div className="card-footer py-0 m-0">
                        <span className="hinttext text-muted">
                          You can upload maximum of 6 Images/Videos
                        </span>
                      </div>
                    </div>

                    <div className="card mb-2  d-none">
                      <div className="card-body">
                        <div className="form-group my-2">
                          <input
                            type="file"
                            className="form-control m-0 "
                            name="file"
                            id="file"
                            aria-describedby="helpId"
                            ref={inputFile}
                            onChange={selectHandler}
                            multiple
                          />
                        </div>
                      </div>
                    </div>

                    <div className="heading">
                      <h6>Wholesale Pricing</h6>
                    </div>

                    <div className="card mb-2">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group my-2">
                              <label htmlFor="price" className="form-label">
                                Price Of Packaging (Pkg.)
                              </label>
                              <input
                                type="text"
                                className="form-control   m-0"
                                name="pkg_price"
                                id="price"
                                aria-describedby="helpId"
                                placeholder="Price of packaging will be auto caclulated"
                                disabled
                                value={newProduct.price * newProduct.pkg}
                              />
                              <small
                                id="helpId"
                                className="form-text text-muted"
                              >
                                Discount on Prices can be made while creating
                                dynamic catalogs.
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group my-2">
                              <label htmlFor="inventory" className="form-label">
                                Unit Value
                              </label>
                              <input
                                type="number"
                                className="form-control m-0"
                                name="pkg"
                                id="unit_value"
                                aria-describedby="helpId"
                                placeholder="0.0"
                                onChange={onInputFormData}
                              />
                              <small
                                id="helpId"
                                className="form-text text-muted"
                              >
                                Per {newProduct.pkg_unit} ({newProduct.pkg} {newProduct.unit_name})
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group my-2">
                              <label htmlFor="inventory" className="form-label">
                                Packaging Unit
                              </label>
                              <input
                                type="text"
                                className="form-control m-0"
                                name="pkg_unit"
                                id="unit"
                                aria-describedby="helpId"
                                placeholder="Unit"
                                onChange={onInputFormData}
                              />
                            </div>
                          </div>
                        </div>

                        <hr className="horizontalline" />

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group my-2">
                              <label htmlFor="price" className="form-label">
                                Price Of Master Packaging (MPkg.)
                              </label>
                              <input
                                type="text"
                                className="form-control   m-0"
                                name="price"
                                id="price"
                                aria-describedby="helpId"
                                placeholder="Price of M.packaging will be auto caclulated"
                                disabled
                                value={
                                  newProduct.price *
                                  newProduct.mpkg *
                                  newProduct.pkg
                                }
                              />
                              <small
                                id="helpId"
                                className="form-text text-muted"
                              >
                                Discount on Prices can be made while creating
                                dynamic catalogs.
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group my-2">
                              <label htmlFor="inventory" className="form-label">
                                Unit Value
                              </label>
                              <input
                                type="number"
                                className="form-control m-0"
                                name="mpkg"
                                id="unit_value"
                                aria-describedby="helpId"
                                placeholder="0.0"
                                onChange={onInputFormData}
                              />
                              <small
                                id="helpId"
                                className="form-text text-muted"
                              >
                                Per {newProduct.mpkg_unit} ({newProduct.mpkg} {newProduct.pkg_unit})
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group my-2">
                              <label htmlFor="inventory" className="form-label">
                                Master Packaging Unit
                              </label>
                              <input
                                type="text"
                                className="form-control m-0"
                                name="mpkg_unit"
                                id="unit"
                                aria-describedby="helpId"
                                placeholder="Unit"
                                onChange={onInputFormData}
                              />
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>

                    <div className="heading">
                      <h6>Accepting Orders</h6>
                    </div>

                    <div className="card mb-2">
                      <div className="card-body">
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <label
                                  htmlFor="mpkg"
                                  className="form-label p-0 m-0"
                                >
                                  Accept Orders For
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="form-check mt-3">
                                  <input
                                    name="default-radio-1"
                                    className="form-check-input"
                                    type="radio"
                                    value="p_unit"
                                    id="defaultRadio1"
                                    onClick={onacceptCheck}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultRadio1"
                                  >
                                    {" "}
                                    Product Unit{" "}
                                  </label>
                                </div>
                              </td>

                              <td>
                                <div className="form-check mt-3">
                                  <input
                                    name="default-radio-1"
                                    className="form-check-input"
                                    type="radio"
                                    value="pkg_unit"
                                    id="defaultRadio1"
                                    onClick={onacceptCheck}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultRadio1"
                                  >
                                    {" "}
                                    Packaging Unit{" "}
                                  </label>
                                </div>
                              </td>

                              <td>
                                <div className="form-check mt-3">
                                  <input
                                    ref={mycheckbox}
                                    name="default-radio-1"
                                    className="form-check-input"
                                    type="radio"
                                    value="mpkg_unit"
                                    id="defaultRadio1"
                                    onClick={onacceptCheck}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultRadio1"
                                  >
                                    {" "}
                                    M.Packaging Unit{" "}
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="form-group my-2">
                          <label htmlFor="m_o_q" className="form-label">
                            Minimum Order Quantity
                          </label>
                          <input
                            type="number"
                            className="form-control   m-0"
                            name="m_o_q"
                            id="m_o_q"
                            aria-describedby="helpId"
                            placeholder="Minimum Order Quantity"
                            onChange={onInputFormData}
                          />
                        </div>

                        <div className="form-check mt-3">
                          <input
                            name="default-radio-1"
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultRadio1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultRadio1"
                          >
                            {" "}
                            Accept orders while out of stock{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2 my-2 saveButton"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
