import React, { useEffect, useState, useContext } from "react";
import Preloader from "../componant/Preloader";
import CusContext from "../context/Customer/CustomerContext";
import Noticontext from "../context/notification/NotifContext";
import { Navigate, useLocation } from "react-router-dom";
//require("dotenv").config();
export default function Payment(props) {
  const host = process.env.REACT_APP_URL;
  const [Payment, setPayments] = useState([]);
  const [preloader, setPreloader] = useState("");

  const notiContext = useContext(Noticontext);

  const [pay_cus_id, set_pay_cus_id] = useState("");
  const { state } = useLocation();
  if (state) {
    const { id } = state;
    set_pay_cus_id(id);
  }

  const { pushNotification } = notiContext;
  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }

  const getSupplierPayments = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/payment/getPaymentSupplier`,
      requestOptions
    );
    const datares = await response.json();
    setPayments(datares.data);
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      getSupplierPayments();
      getAllCustomer();
    }
  }, []);

  // fetching customers
  const context = useContext(CusContext);
  const { myCustomer, getAllCustomer, addCustomer, getAllOrder } = context;

  const [bodydata, setBodyData] = useState({
    userid: "",
    amt: 0,
    collectedBy: "",
    mode: "",
    desc: "",
    createBy: "user",
  });

  const addPayment = async (data) => {
    setPreloader("preShow");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${host}/api/payment/createPayment/supplier`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "Success") {
      props.showAlert("Payment Has Been Created", "success");

      pushNotification(
        datares.data.userid,
        "customer-payments",
        "New Payment Added",
        "Payment has been added from supplier. Amt is " + data.amt
      );
    } else {
      props.showAlert(datares.msg, "danger");
    }
    setPreloader("");
    setPayments(Payment.concat(datares.data));
  };

  const onInputData = (e) => {
    e.preventDefault();
    const namekey = e.target.name;
    const keyvalue = e.target.value;
    setBodyData({ ...bodydata, [namekey]: keyvalue });
  };

  const clickAddPay = (e) => {
    e.preventDefault();
    addPayment(bodydata);
  };

  const [query, setQuery] = useState("");
  return (
    <>
      <Preloader show={preloader} />
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">Add Payment</span>

              {/* <div className="heading_buttons">
              
              </div> */}
            </h4>
            <div className="col-lg-12 mb-4 order-0 mx-auto">
              <div className="card">
                <div className="d-flex align-items-center row">
                  <div className="col-sm-12 ">
                    <div className="card-body">
                      <form
                        id="formAccountSettings"
                        method="POST"
                        onSubmit={clickAddPay}
                      >
                        <div className="row">
                          <div className="mb-3 col-md-2">
                            <label for="createAt" className="form-label">
                              Select Date <span className="important">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              id="createAt"
                              name="createAt"
                              onChange={onInputData}
                            />
                          </div>
                          <div className="mb-3 col-md-2">
                            <label for="amt" className="form-label">
                              Amount <span className="important">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="amt"
                              name="amt"
                              placeholder="0.0"
                              onChange={onInputData}
                            />
                          </div>

                          <div className="mb-3 col-md-2">
                            <label for="userid" className="form-label">
                              Customer Name <span className="important">*</span>
                            </label>
                            <select
                              className="form-control"
                              type="text"
                              id="userid"
                              name="userid"
                              onChange={onInputData}
                            >
                              <option value={0}>Select Customer</option>
                              {myCustomer.map((cus) => {
                                return (
                                  <option value={cus.customer_id._id}>
                                    {cus.customer_id.fullname}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="mb-3 col-md-2">
                            <label for="collectedBy" className="form-label">
                              Collected By <span className="important">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="collectedBy"
                              name="collectedBy"
                              placeholder="Ex. John"
                              onChange={onInputData}
                            />
                          </div>

                          <div className="mb-3 col-md-2">
                            <label for="mode" className="form-label">
                              Payment Mode
                            </label>
                            <select
                              className="form-control"
                              type="text"
                              id="mode"
                              name="mode"
                              onChange={onInputData}
                            >
                              <option>Cash</option>
                              <option>Online</option>
                              <option>Cheque</option>
                            </select>
                          </div>
                          <div className="mb-3 col-md-2">
                            <label for="desc" className="form-label">
                              Description
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="desc"
                              name="desc"
                              placeholder="Enter Description"
                              onChange={onInputData}
                            />
                          </div>
                        </div>
                        <div className=" text-right mt-3">
                          <button
                            type="reset"
                            className="btn my-scale-btn btn-outline-primary me-2 my-btn"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn my-scale-btn orange-btn btn-primary me-2"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Payment History</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Payments..."
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Amount</th>
                        <th>Customer Details</th>
                        <th>Date</th>
                        <th>Mode</th>
                        <th>Collected By</th>
                        <th>Description</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {Payment.filter((pay) =>
                        pay_cus_id ? pay.userid._id === pay_cus_id : pay
                      ).map((pay) => {
                        return (
                          <>
                            {pay.amt > 0 && (
                              <tr>
                                <td>{pay.amt}</td>
                                <td>{pay.userid.fullname}</td>
                                <td>{pay.createAt}</td>
                                <td>{pay.mode}</td>
                                <td>{pay.collectedBy}</td>
                                <td>{pay.desc}</td>
                                <td className="action">
                                  {pay.approve ? (
                                    ""
                                  ) : (
                                    <span class="badge badge-secondary me-2">
                                      <i class="bx bx-check"></i>
                                    </span>
                                  )}

                                  {/* <span class="badge badge-secondary me-2">
                                <i className="bx bx-edit-alt "></i>
                              </span> */}
                                  <span class="badge badge-secondary my-delete">
                                    <i className="bx bx-trash-alt "></i>
                                  </span>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
