import React, { useContext, useEffect, useState } from "react";
import Alert from "../componant/Alert";
import Preloader from "../componant/Preloader";
import CatContext from "../context/Category/catContext";
import InfiniteScroll from "react-infinite-scroll-component";
import Search_Query from "../componant/search_query/Search_Query";
import Nodata from "../componant/NodataModel/Nodata";

export const Category = (props) => {
  const mycontext = useContext(CatContext);
  const {
    category,
    totalCount,
    getAllCategory,
    updateCategory,
    addCategory,

    addSubCategory,
    deleteCategoryapi,
    deleteSubCategoryapi,
    updateSubCategoryApi,
  } = mycontext;

  const [preloader, setPreloader] = useState("");

  useEffect(() => {
    getAllCategory();
  }, []);

  // if category is ===0 then say no data available

  // add Category
  const [newCategory, setCategory] = useState({
    cat_name: "",
    c_id: localStorage.getItem("com_id"),
  });

  const [query, setQuery] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (edit_status) {
      const restr = await updateCategory(edit_obj._id, {
        cat_name: newCategory.cat_name,
      });
      if (restr === "Success") {
        openData();
      }
    } else {
      const restr = await addCategory(newCategory);
      setAddCategoryModal(!addCategoryModal);
    }
    setPreloader("");
  };

  const onChange = (e) => {
    console.log(e.target.name);
    setCategory({ ...newCategory, [e.target.name]: e.target.value });
  };
  // add Category

  // add sub Category
  const [id, setid] = useState("");
  const [newSubcat, setSub] = useState({
    name: "",
  });

  const subHandleClick = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const restr = await addSubCategory(newSubcat, id);
    if (restr.status === "Sucess") {
      openSubData();
      showAlert("Created", "success");
    } else {
      showAlert(restr.msg, "danger");
    }
    setPreloader("");
    //console.log(restr);
  };

  const srtId = (e) => {
    setid(e.target.value);
  };

  const onsubhange = (e) => {
    setSub({ ...newSubcat, [e.target.name]: e.target.value });
  };
  // add sub Category

  // delete sub category

  const deleteSubCategory = async (s_id, p_id) => {
    setPreloader("preShow");
    const res = await deleteSubCategoryapi({ id: s_id }, p_id);

    if (res.status === "Failed") {
      showAlert(res.msg, "danger");
    } else {
      showAlert(res.msg, "success");
    }
    setPreloader("");
  };

  // delete whole  category
  const deleteCategory = async (id) => {
    setPreloader("preShow");
    const cat = await deleteCategoryapi(id);
    setPreloader("");
  };

  // showaler

  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
      active: "alert-active",
    });
    setTimeout(() => {
      setAlert(null);
    }, 2000);
  };

  const style = {
    background: "#00000080",
    display: "block",
  };
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [addsubCategoryModal, setAddsubCategoryModal] = useState(false);
  const openData = () => {
    setAddCategoryModal(!addCategoryModal);
    set_edit_status(false);
  };
  const openSubData = () => {
    setAddsubCategoryModal(!addsubCategoryModal);
  };

  // edit category
  const [edit_status, set_edit_status] = useState(false);
  const [edit_obj, set_edit_obj] = useState({});

  const openEditModal = (obj) => {
    set_edit_status(true);
    setAddCategoryModal(true);
    set_edit_obj(obj);
  };

  // edit sub category
  const [edit_sub_status, set_edit__sub_status] = useState(false);
  const [edit_sub_obj, set_edit_sub_obj] = useState({});
  const [p_sub_id, set_p_sub_id] = useState("");
  const openSubCategoryEdit = (obj, p_id) => {
    set_edit__sub_status(true);
    // setAddCategoryModal(true);
    set_edit_sub_obj(obj);
    set_p_sub_id(p_id);
  };

  const updateSubCategory = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const restr = await updateSubCategoryApi({
      cat_name: edit_sub_obj.name,
      p_id: p_sub_id,
      sub_id: edit_sub_obj._id,
    });

    if (restr === "Success") {
      set_edit__sub_status(false);
      showAlert("Sub Category has been updated.", "success");
    } else {
      showAlert(restr.msg, "danger");
    }
    setPreloader("");
  };

  // load more function
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const fetchFunction = (que) => {
    console.log(
      "category load more function called" +
        category.length +
        " - " +
        totalCount
    );
    if (category.length === totalCount) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
      setPage(page + 20);
      getAllCategory(true, page + 20, que, (length) => {
        if (length === 0) {
          setLoadMore(false);
        }
      });
    }
  };

  console.log("====================================");
  console.log("Category Page" + category.length);
  console.log("====================================");

  return (
    <>
      <Preloader show={preloader} />
      {alert && <Alert alert={alert} />}

      {addCategoryModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {edit_status ? "Edit Category" : "Add Category"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="cat_name"
                            id=""
                            defaultValue={edit_status ? edit_obj.cat_name : ""}
                            aria-describedby="helpId"
                            placeholder="Category Name"
                            onChange={onChange}
                          />
                        </div>
                      </form>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3" style={{ textAlign: "right" }}>
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={openData}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      {edit_status ? "Save" : "Add"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {addsubCategoryModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Sub Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openSubData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    <div className="col-md-12 my-1">
                      <form method="post">
                        <div className="form-group">
                          <select
                            className="form-control    m-0 mb-3"
                            name="id"
                            id=""
                            onChange={srtId}
                          >
                            <option>Choose Primary Category</option>
                            {category.map((c, i) => {
                              return (
                                <option key={i} value={c._id}>
                                  {c.cat_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Sub Category Name"
                            onChange={onsubhange}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="mt-2" style={{ textAlign: "right" }}>
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={openSubData}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={subHandleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>

                {/* <p className="instruction_para">
    [Customer Name] will recieve an invitation from your Company,
    when he will accept your invitation your customer will be
    added.
  </p> */}
              </div>
              {/* <div className="modal-footer">
  <button
    type="button"
    className="btn btn-primary orange-btn me-2 mb-2"
  >
    Create Now
  </button>
</div> */}
            </div>
          </div>
        </div>
      )}

      {edit_sub_status && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Sub Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    set_edit__sub_status(!edit_sub_status);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={updateSubCategory}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="cat_name"
                            id=""
                            defaultValue={edit_sub_obj.name}
                            aria-describedby="helpId"
                            placeholder="Category Name"
                            onChange={(e) => {
                              set_edit_sub_obj({
                                ...edit_sub_obj,
                                ["name"]: e.target.value,
                              });
                              // edit_sub_obj.name = e.target.value
                            }}
                          />
                        </div>
                      </form>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3" style={{ textAlign: "right" }}>
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        set_edit__sub_status(!edit_sub_status);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      // onClick={handleClick}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          {category.length > 0 && (
            <div className="fixed-button">
              <div
                className="btn btn-sm fixed_button btn-outline-primary"
                onClick={openData}
              >
                + Add Category
              </div>
              <div
                className="btn btn-sm bulkuplod btn-outline-primary"
                onClick={openSubData}
              >
                + Add Sub Category
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-lg-12">
              <h4 className=" c_heading">
                <span className="">My Categories ({totalCount})</span>
              </h4>
            </div>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-12 text-right">
                      <Search_Query
                        value={query}
                        show={query !== ""}
                        onQuery={setQuery}
                        clear_data={() => {
                          setQuery("");
                          setPage(1);
                          getAllCategory(false, 1, "", (l) => {
                            // setLoadMore(true);
                            // fetchFunction("");
                          });
                        }}
                        search_click={() => {
                          setPage(1);
                          getAllCategory(false, 1, query, (l) => {
                            fetchFunction("");
                          });
                          setLoadMore(true);
                        }}
                      />
                    </div>

                    <div className="col-lg-6 font-bold text-right"></div>
                  </div>
                </h5>

                <div className="table-responsive1 text-nowrap mb-1">
                  <InfiniteScroll
                    hasMore={loadMore}
                    next={() => {
                      fetchFunction(query);
                    }}
                    dataLength={category.length}
                    loader={
                      <>
                        <div
                          className="loader"
                          style={{
                            position: "relative",
                            width: 40,
                            height: 40,
                          }}
                        ></div>
                        {/* <span>
                          If not loaded data{" "}
                          <strong
                            onClick={() => {
                              // fetchMoreData(page + 8, filter_type);
                            }}
                          >
                            Click Here
                          </strong>
                        </span> */}
                      </>
                    }
                  >
                    <table className="table table-hover">
                      <thead className="order_history_header">
                        <tr>
                          <th>Primary Category</th>
                          <th>Sub Category</th>
                          <th className="action">Action</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0 order_history_body">
                        {category.map((c, i) => (
                          <tr key={i}>
                            <td>{c.cat_name}</td>
                            <td>
                              {c.sub_items.map((s, j) => (
                                <div className="dropdown subcategory" key={j}>
                                  <button
                                    type="button"
                                    className="btn p-0 dropdown-toggle hide-arrow"
                                    data-bs-toggle="dropdown"
                                  >
                                    {s.name}
                                    <i className="bx bx-dots-vertical-rounded"></i>
                                  </button>
                                  <div className="dropdown-menu">
                                    <p
                                      className="dropdown-item"
                                      onClick={() => {
                                        openSubCategoryEdit(s, c._id);
                                      }}
                                    >
                                      <i className="bx bx-edit me-1"></i> Edit
                                    </p>
                                    <p
                                      className="dropdown-item"
                                      onClick={() => {
                                        props.showCon(
                                          "Are you sure you want to delete this Sub Category?",
                                          true,
                                          () => {
                                            deleteSubCategory(s._id, c._id);
                                            props.showCon("", false, {});
                                          }
                                        );
                                      }}
                                    >
                                      <i className="bx bx-trash me-1"></i>{" "}
                                      Delete
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </td>
                            <td className="action">
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn p-0 dropdown-toggle hide-arrow"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="bx bx-dots-vertical-rounded"></i>
                                </button>
                                <div className="dropdown-menu">
                                  <p
                                    className="dropdown-item"
                                    onClick={() => {
                                      openEditModal(c);
                                    }}
                                  >
                                    <i className="bx bx-edit-alt me-1"></i> Edit
                                  </p>
                                  <p
                                    className="dropdown-item"
                                    onClick={() => {
                                      props.showCon(
                                        "Are you sure you want to delete this category?",
                                        true,
                                        async () => {
                                          deleteCategory(c._id);
                                          props.showCon("", false, {});
                                        }
                                      );
                                    }}
                                  >
                                    <i className="bx bx-trash me-1"></i> Delete
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            {/* table layout */}
          </div>
        </div>
      </div>
    </>
  );
};
