import React, { useState, useContext, useEffect } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import MyButton from "../componant/MyButton";
import CusContext from "../context/Customer/CustomerContext";
import CustomButton from "../componant/CustomButton";
import ConfirmAlert from "../componant/ConfirmAlert/ConfirmAlert";
import Search_Query from "../componant/search_query/Search_Query";
import InfiniteScroll from "react-infinite-scroll-component";

export const Customer = (props) => {
  const userData = JSON.parse(localStorage.getItem("user_data"));
  // data from custormercontext
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const showalert = (message, type) => {
    props.showAlert(message, type);
  };

  const context = useContext(CusContext);
  const {
    myCustomer,
    totalCount,
    getAllCustomer,
    addCustomer,
    updateStatus,
    getAllOrder,
    getEnvite,
    invites,
    deleteInvite,
    deleteCustomer,
    updateCustomer,
  } = context;

  const [newCustomer, setCustomer] = useState({
    phone: "",
    fullname: "",
    password: "",
    type: "user",
  });

  // update status
  const [edit_customer, setEdit_customer] = useState(false);
  const [edit_object, set_edit_object] = useState(null);
  const [modal, setModal] = useState(false);
  const [uploadModal, setUploadmodeal] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);
  const [pendingModel, setPendingModel] = useState(false);
  const [delete_inv_id, setdeleteId] = useState("");
  const [query, setQuery] = useState("");

  const [p_filter, setFilter] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("com_id")) {
      getAllCustomer();
    }
  }, []);

  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }

  const go_to_cus_details = (item) => {
    navigate("/customerDetails", { state: item });
  };

  const updateOutStanding = async () => {
    const newdata = await myCustomer.map(async (cus) => {
      const order = await getAllOrder(cus.customer_id._id);
      return { ...cus, order };
    });
    console.log(newdata);
  };

  // console.log(myCustomer);
  const uploadCustomer = async (e) => {
    e.preventDefault();
    console.log("clicked");

    if (edit_customer) {
      const res = await updateCustomer(newCustomer.id, newCustomer.fullname);
      if (res.status === "Failed") {
        showalert(res.msg, "danger");
      } else {
        showalert("Customer has been updated", "success");
        openData();
      }
    } else {
      const restr = await addCustomer(newCustomer);
      console.log(restr);
      if (restr.status === "Failed") {
        showalert(restr.msg, "danger");
      } else {
        showalert("Customer has been added", "success");
        openData();
      }
    }
  };

  const inputFuction = (e) => {
    setCustomer({ ...newCustomer, [e.target.name]: e.target.value });
  };

  const style = {
    background: "#00000080",
    display: "block",
  };

  const openData = () => {
    setModal(!modal);
    setEdit_customer(false);
  };

  const openUploadModal = () => {
    setUploadmodeal(!uploadModal);
  };

  const openconfirmModal = () => {
    setconfirmModal(!confirmModal);
  };

  const openPending = () => {
    setPendingModel(!pendingModel);
  };

  const inviteDelete = (id) => {
    // console.log(id);
    setdeleteId(id);

    openconfirmModal();
  };
  const deleteRequest = () => {
    openconfirmModal();
    deleteInvite(delete_inv_id);
    // console.log(delete_inv_id);
  };

  const filterfunction = (title) => {
    setFilter(false);
    if (title === "Active") {
      setFilter(true);
    } else if (title === "Block") {
      setFilter(false);
    } else if (title === "Pending") {
      getEnvite(userData._id);
      openPending();
      setFilter(true);
    }
  };

  const btn_data = [
    {
      title: "Active",
    },
    {
      title: "Pending",
    },
    {
      title: "Block",
    },
  ];

  return (
    <>
      {/* modal add customer */}

      {pendingModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Pending Users
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openPending}
                ></button>
              </div>
              <div className="modal-body">
                <table className="table table-hover">
                  <thead className="order_history_header">
                    <tr>
                      <th>S. No</th>
                      <th>Customer Name</th>
                      <th>Phone Number</th>
                      <th className="action">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invites.map((inv, i) => {
                      return inv.status === "pending" ? (
                        <tr>
                          <td>{i}</td>
                          <td>{inv.nick_name}</td>
                          <td>{inv.cus_id.phone}</td>
                          <td className="action">
                            <span
                              className="badge badge-secondary my-delete"
                              onClick={() => {
                                inviteDelete(inv._id);
                              }}
                            >
                              <i className="bx bx-trash-alt "></i>
                            </span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "35%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {edit_customer ? "Edit Customer" : "Create Customer"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form method="POST">
                  <div className="mb-3">
                    <label htmlFor="fullname" className="col-form-label">
                      Customer Name :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="fullname"
                      id="fullname"
                      placeholder="Customer Name"
                      onChange={inputFuction}
                      defaultValue={edit_customer ? edit_object.nick_name : ""}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="col-form-label">
                      Customer Phone No. :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Customer Phone No."
                      onChange={inputFuction}
                      disabled={edit_customer}
                      defaultValue={
                        edit_customer ? edit_object.customer_id.phone : ""
                      }
                    />
                  </div>

                  {/* <div className="mb-3">
                    <label htmlFor="password" className="col-form-label">
                      Password
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Customer Password"
                      onChange={inputFuction}
                    />
                  </div> */}
                </form>

                {!edit_customer ? (
                  <p className="instruction_para">
                    {newCustomer.fullname
                      ? newCustomer.fullname
                      : "[Customer Name]"}{" "}
                    will recieve an invitation, your company will be added in{" "}
                    {newCustomer.fullname
                      ? newCustomer.fullname
                      : "[Customer Name]"}
                    's supplier section from where he can place you orders.
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary orange-btn me-2 mb-2"
                  onClick={uploadCustomer}
                >
                  {edit_customer ? "Save" : "Create Now"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {uploadModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Bulk Upload Customers From Excel
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openUploadModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Choose File :
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="review_name"
                      placeholder="Customer Name"
                    />
                  </div>
                </form>

                <p className="instruction_para">
                  Upload the Excelsheet of customer's name and phone numbers in
                  the following format.
                </p>
                <Link>
                  <u>Download Demo Sheet.</u>
                </Link>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary orange-btn me-2 mb-2"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {confirmModal && (
        <ConfirmAlert
          style={{ display: "block" }}
          heading={
            "Are you sure, you want to remove this customer from pending list?"
          }
          open={openconfirmModal}
          confirm={deleteRequest}
        />
      )}

      {/* modal add customer */}

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className="btn btn-sm fixed_button btn-outline-primary"
              onClick={openData}
            >
              + Add Customer
            </div>

            <div
              className="btn btn-sm  bulkuplod btn-outline-primary"
              onClick={openUploadModal}
            >
              + Upload From Excel
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Customers ({myCustomer.length})
              </span>

              <div className="heading_buttons"></div>
            </h4>

            {myCustomer.length === 0 && "No Customer Found"}

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 text-right">
                      <Search_Query
                        placeholder="Search Products ..."
                        value={query}
                        show={query !== ""}
                        onQuery={setQuery}
                        clear_data={() => {
                          setQuery("");
                          getAllCustomer("");
                          // getAllProduct(false, 1, "", (l) => {
                          //   setPage(1);
                          //   setLoadMore(true);
                          // });
                        }}
                        search_click={() => {
                          getAllCustomer(query);
                          // getAllProduct(false, 1, query);
                          // setPage(1);
                          // setLoadMore(true);
                        }}
                      />
                    </div>

                    <div className="col-lg-6 font-bold">
                      <CustomButton
                        buttondata={btn_data}
                        filterfunction={filterfunction}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Profile Pic.</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Out Standing Balance</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {/* {myCustomer.length === 0 && "No Customer Found"} */}
                      {myCustomer
                        .filter(
                          (cus) => cus.status === p_filter
                          // && (cus.nick_name.includes(query) ||
                          //     cus.customer_id.phone.toString().includes(query))
                        )
                        .map((sup) => {
                          return (
                            <tr key={sup._id}>
                              <td>
                                <Link
                                  to={`/customerDetails/${sup.customer_id._id}`}
                                >
                                  <img
                                    src={
                                      sup.com_data &&
                                      sup.com_data.com_image !== ""
                                        ? sup.com_data.com_image
                                        : "../assets/img/avatars/5.png"
                                    }
                                    alt="Avatar"
                                    width="60"
                                    height="60"
                                    className="rounded-circle customer_image my-auto"
                                  />
                                </Link>
                              </td>

                              <td>
                                <span
                                  style={{
                                    color: "black",
                                    fontFamily: "pop_bold",
                                  }}
                                  onClick={() => {
                                    go_to_cus_details(sup);
                                  }}
                                >
                                  {sup.nick_name}
                                </span>
                                <br />{" "}
                                <span
                                  style={{ color: "black", fontSize: "12px" }}
                                >
                                  {sup.com_data
                                    ? sup.com_data.com_name
                                    : "Company - N/A"}
                                </span>{" "}
                                {/* </Link> */}
                              </td>
                              <td>
                                <Link
                                  to={`/customerDetails/${sup.customer_id._id}`}
                                >
                                  {sup.customer_id.phone}
                                </Link>
                              </td>
                              <td>{sup.customer_id.address}</td>
                              <td>
                                <span
                                  className="badge rounded-pill bg-label-success w-100"
                                  style={{ fontSize: "1.4em" }}
                                >
                                  ₹{" "}
                                  {parseInt(sup.out_standing) < 0
                                    ? parseFloat(sup.out_standing).toFixed(2) +
                                      " (Adv)"
                                    : parseFloat(sup.out_standing).toFixed(2)}
                                </span>
                              </td>
                              <td className="action">
                                {p_filter === true ? (
                                  <>
                                    <span
                                      className="badge badge-secondary my-delete me-1"
                                      onClick={() => {
                                        navigate(
                                          "/createorder/supplier/" +
                                            sup.customer_id._id
                                        );
                                      }}
                                    >
                                      <i className="bx bxs-cart-add "></i>
                                    </span>

                                    <span
                                      className="badge badge-secondary my-delete me-1"
                                      onClick={() => {}}
                                    >
                                      <i className="bx bx-rupee "></i>
                                    </span>

                                    {/* Payment section */}
                                    <span
                                      className="badge badge-secondary my-delete me-1"
                                      onClick={() => {
                                        navigate("/payments", {
                                          state: { id: sup.customer_id._id },
                                        });
                                      }}
                                    >
                                      <i className="bx bx-money "></i>
                                    </span>

                                    <span
                                      className="badge badge-secondary my-delete me-1"
                                      onClick={() => {}}
                                    >
                                      <i className="bx bxs-report "></i>
                                    </span>

                                    <span
                                      className="badge badge-secondary my-delete me-1"
                                      onClick={() => {
                                        setEdit_customer(true);
                                        setModal(true);
                                        set_edit_object(sup);
                                        setCustomer({
                                          id: sup.customer_id._id,
                                          fullname: sup.nick_name,
                                        });
                                      }}
                                    >
                                      <i className="bx bxs-edit "></i>
                                    </span>

                                    <span
                                      className="badge badge-secondary my-delete me-1"
                                      onClick={async () => {
                                        const res = await updateStatus(
                                          "block",
                                          sup.customer_id._id
                                        );
                                      }}
                                    >
                                      <i className="bx bx-block "></i>
                                    </span>

                                    <span
                                      className="badge badge-secondary my-delete me-1"
                                      onClick={() => {
                                        props.showCon(
                                          "Are you sure, you want to delete your customer. \nAll the associated data will be deleted.",
                                          true,
                                          async () => {
                                            const res = await deleteCustomer(
                                              sup.customer_id._id
                                            );
                                            props.showCon("", false, {});
                                          }
                                        );
                                      }}
                                    >
                                      <i className="bx bx-trash-alt "></i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="badge badge-secondary my-delete me-1"
                                      onClick={async () => {
                                        const res = await updateStatus(
                                          "unblock",
                                          sup.customer_id._id
                                        );
                                      }}
                                    >
                                      <i className="bx bx-check "></i>
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
