import React from "react";
import { useState } from "react";

function CustomButton({
  buttondata,
  filterfunction,
  widthM = 340,
  m_right = 0,
}) {
  let width = 100 / buttondata.length;

  const [activeIndex, setactiveIndex] = useState(0);

  return (
    <>
      <div className="wraper" style={{ marginRight: m_right }}>
        <div className="btn_group" style={{ width: widthM }}>
          {buttondata.map((btn, i) => {
            const bg = i === activeIndex ? "#ef6817" : "";
            const active = i === activeIndex ? "white" : "black";
            return (
              <p
                className="btn_one slid_btn_active "
                style={{
                  width: width + "%",
                  background: bg,
                  color: active,
                }}
                id="block"
                onClick={() => {
                  setactiveIndex(i);
                  filterfunction(btn.title);
                }}
              >
                <span>{btn.title}</span>
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default CustomButton;
