import React, { useContext, useState, useEffect } from "react";
import AddNewCatalog from "../componant/AddCatalog/AddCatalog";
import ManageProduct from "../componant/MangeProduct";
import MyManageButton from "../componant/MyManageButton";
import catalogContext from "../context/Catalog/CatalogContext";

import CusContext from "../context/Customer/CustomerContext";
import productContext from "../context/Product/productContext";
import AddnewCatlog from "./AddnewCatalog";
import { Navigate } from "react-router-dom";
import CatalogManage from "../componant/CatalogManage";

export default function Catelog(props) {
  const [modal, setModal] = useState(false);
  const [addnewCatalog] = useState(false);

  const openData = () => {
    setModal(!modal);
    setEditStatus(false);
  };

  const context = useContext(catalogContext);
  const cusContext = useContext(CusContext);
  const { myCustomer, getAllCustomer, setCustomerData } = cusContext;
  const {
    catalog,
    getAllCatalog,
    deleteCatalog,
    updateCatalog,
    updateProductCatalog,
  } = context;

  const procontext = useContext(productContext);
  const { getAllProductbyChecked } = procontext;

  const [catalogDetails, setCatalogDeatils] = useState([]);
  const [selectCustomer, setSelecteCustomer] = useState([]);
  const [btnName, setBtnName] = useState("Save");

  const [ManageType, setManageType] = useState("active");

  const [query, setQuery] = useState("");

  const [editStatus, setEditStatus] = useState(false);
  const [edit_object, set_edit_object] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("com_id")) {
      getAllCatalog(localStorage.getItem("com_id"));
    }
  }, []);

  if (!localStorage.getItem("com_id")) {
    return <Navigate to="/mycompanies" />;
  }

  // catelog edit data

  const setCatalog = async (data) => {
    setCatalogDeatils(data);
    let customers = await getAllCustomer();
    console.log("get customer");
    console.log(customers);

    let myArr = [];

    for (let i = 0; i < customers.data.length; i++) {
      let element = customers.data[i];
      console.log("first cus match data - " + element.customer_id._id);
      console.log(element);
      for (let j = 0; j < data.customers.length; j++) {
        const assigned = data.customers[j];

        if (assigned.cus_id.toString() === element.customer_id._id) {
          element.ischecked = true;
          myArr.push({ cus_id: element.customer_id._id });

          break;
        } else {
          element.ischecked = false;
        }
      }
    }

    setSelecteCustomer(myArr);

    setCustomerData(customers.data);

    console.log(myCustomer);
    getAllProductbyChecked(data);
  };
  // select all
  const handleChange = async (e) => {
    const { name, checked } = e.target;
    const myArr = [];
    if (name.toString() === "all") {
      let tempUser = myCustomer.map((cus) => {
        myArr.push({ cus_id: cus.customer_id._id });
        return { ...cus, ischecked: checked };
      });
      setSelecteCustomer(myArr);
      setCustomerData(tempUser);
      console.log("all Seelcte");
    } else {
      const myArr = [];
      let tempUser = myCustomer.map((cus) =>
        cus.customer_id.phone.toString() === name.toString()
          ? { ...cus, ischecked: checked }
          : cus
      );

      tempUser.map((cus) => {
        console.log(cus);
        if (cus.ischecked) {
          myArr.push({ cus_id: cus.customer_id._id });
        }
      });
      setSelecteCustomer(myArr);
      setCustomerData(tempUser);
    }
  };

  const assignCustomer = async (e) => {
    e.preventDefault();
    const res = await updateCatalog(selectCustomer, catalogDetails._id);
    if (res === "Sucess") {
      props.showAlert("Catlog has been updated.", "success");
    }
  };

  const edit_status = (obj) => {
    setEditStatus(!editStatus);
    setModal(!modal);
    set_edit_object(obj);
  };

  // load more

  return (
    <>
      {modal && (
        <AddNewCatalog
          open={openData}
          showAlert={props.showAlert}
          edit_status={editStatus}
          edit_data={edit_object}
        />
      )}

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className="btn btn-sm fixed_button btn-outline-primary"
              onClick={openData}
            >
              + Add Catalog
            </div>
          </div>

          {addnewCatalog && <AddnewCatlog />}

          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Catalogs ({catalog.length})
              </span>
            </h4>

            <div className="col-md-12">
              <div className="row addnewproduct py-3">
                <div className="col-md-12">
                  <div className="list_container">
                    <div className="d-flex flex-nowrap overflow-auto">
                      {catalog.length === 0 && "Catalog Not Found"}
                      {catalog.map((cat, index) => {
                        return (
                          <div
                            className="me-2"
                            style={{ flex: "0 0 25%" }}
                            key={cat._id}
                            onClick={() => {
                              setCatalog(cat);
                            }}
                          >
                            <div
                              className={`card mb-3 mycatelog asd ${
                                cat._id === catalogDetails._id && "active"
                              }`}
                            >
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-10">
                                    <h5 className="card-title m-0">
                                      {cat.catalog_name}
                                    </h5>
                                    <small className="catenickname">
                                      {cat.nick_name}
                                    </small>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="right-edit-align">
                                      <div className="dropdown">
                                        <button
                                          type="button"
                                          className="btn p-0 dropdown-toggle hide-arrow"
                                          data-bs-toggle="dropdown"
                                        >
                                          <i className="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                          <p
                                            className="dropdown-item"
                                            onClick={() => {
                                              edit_status(cat);
                                            }}
                                          >
                                            <i className="bx bx-edit me-1"></i>
                                            Edit
                                          </p>

                                          <p
                                            className="dropdown-item"
                                            onClick={() => {
                                              deleteCatalog(cat._id);
                                            }}
                                          >
                                            <i className="bx bx-trash me-1"></i>
                                            Delete
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <ul className="card-text catalogText mt-2">
                                  <li>
                                    Discount : {}{" "}
                                    {cat.flattDiscount === true
                                      ? "₹ " + cat.discount
                                      : cat.discount + "%"}
                                  </li>
                                  <li>Products : {cat.products.length}</li>
                                  <li>Customers : {cat.customers.length}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {catalogDetails.length !== 0 && (
                  <>
                    <hr style={{ background: "#7272719e" }} />
                    <div className="col-md-12 verticleDivider my-2">
                      <div className="row mb-2">
                        <div
                          className="col-lg-4"
                          style={{
                            color: "var(--bs-dark-blue)",
                            fontFamily: "pop_bold",
                          }}
                        >
                          {catalogDetails.length !== 0
                            ? catalogDetails.catalog_name
                            : "Select Catlog"}

                          <small className="mx-2">
                            (
                            {ManageType === "active"
                              ? "Customer Assigned"
                              : "Product Assigned"}{" "}
                            {selectCustomer.length})
                          </small>
                        </div>
                        <div className="col-lg-3 text-right">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search "
                            onChange={(e) => setQuery(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-5 font-bold">
                          {/* <MyManageButton setManageType={setManageType} /> */}
                          <CatalogManage
                            buttondata={[
                              {
                                title: "Manage Customers",
                              },
                              {
                                title: "Manage Products",
                              },
                            ]}
                            filterfunction={(title) => {
                              if (title.toLowerCase() === "manage products") {
                                setManageType("product");
                              } else {
                                setManageType("active");
                              }

                              setQuery("");
                            }}
                          />
                        </div>
                      </div>

                      {ManageType === "active" ? (
                        <div className="card p-2 mamnageCustomerCard">
                          <div className="card-datatable table-responsive">
                            <div className="fixed-scroll-500">
                              <table className="datatables-basic table border-top">
                                <thead className="order_history_header">
                                  <tr>
                                    <th>
                                      <div
                                        className="form-check m-0"
                                        style={{ minHeight: 1 }}
                                      >
                                        <input
                                          className="form-check-input me-4"
                                          type="checkbox"
                                          value="mpkg_unit"
                                          id="defaultRadio1"
                                          name="all"
                                          onChange={handleChange}
                                          // checked={selectCustomer.length === myCustomer.length}

                                          // defaultChecked={selectCustomer.length === myCustomer.length}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="defaultRadio1"
                                        >
                                          Customer Name({selectCustomer.length})
                                        </label>
                                      </div>
                                    </th>
                                    {/* <th>Customer Name</th> */}
                                    <th>Company</th>
                                    <th>Phone</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {myCustomer
                                    .filter((cus) => {
                                      return (
                                        cus.nick_name &&
                                        cus.nick_name
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                      );
                                    })
                                    .map((cus, i) => {
                                      console.log("my data found");

                                      const com_data = cus.com_data;
                                      console.log(catalogDetails.length);
                                      if (catalogDetails.length !== 0) {
                                      }

                                      return (
                                        <tr key={i}>
                                          <td>
                                            <div className="form-check d-flex align-items-center">
                                              <input
                                                // name="default-radio-1"
                                                className="form-check-input"
                                                type="checkbox"
                                                value="mpkg_unit"
                                                id="defaultRadio1"
                                                name={cus.customer_id.phone}
                                                onChange={handleChange}
                                                checked={cus.ischecked || false}
                                              />
                                              <label
                                                className="form-check-label d-flex align-items-center"
                                                htmlFor="defaultRadio1"
                                              >
                                                <img
                                                  src={
                                                    com_data !== undefined
                                                      ? com_data.com_image
                                                      : "../assets/img/avatars/5.png"
                                                  }
                                                  alt="customer image"
                                                  className="rounded-circle  mx-4 my-auto img-fluid"
                                                  width={50}
                                                />
                                                <span
                                                  className="my-auto"
                                                  style={{
                                                    height: "100%",
                                                    color: "black",
                                                  }}
                                                >
                                                  {cus.nick_name}
                                                </span>
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            {com_data !== undefined
                                              ? com_data.com_name
                                              : "Company N/A"}
                                          </td>

                                          <td>{cus.customer_id.phone}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>

                            <p
                              className="btn orange-btn m-3"
                              onClick={assignCustomer}
                            >
                              {btnName}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <ManageProduct
                          updateProductCatalog={async (data, id) => {
                            const res = await updateProductCatalog(data, id);
                            if (res === "Sucess") {
                              props.showAlert(
                                "Catlog product has been assigned",
                                "success"
                              );
                            }
                          }}
                          cat_data={catalogDetails}
                          query={query}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
