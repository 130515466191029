import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../componant/Preloader";

import "../css/login.css";
import { useContext } from "react";
import ComContext from "../context/Company/CompanyContext";
//require("dotenv").config();

export default function Login(props) {
  const host = process.env.REACT_APP_URL;
  let history = useNavigate();

  const company_context = useContext(ComContext);

  const { setSelectedCompany } = company_context;

  const [modal, setModal] = useState(true);
  const [loginForm, setLoginForm] = useState(true);
  const [otpVerify, setotpVerify] = useState("");
  // showVerity

  const changePanel = () => {
    setModal(!modal);
  };

  const changeOtp = () => {
    setLoginForm(!loginForm);
  };

  const [userRegistration, setUserRegistration] = useState({
    fullname: "",
    phone: 9999999999,
    email: "",
    password: "",
    type: "user",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserRegistration({ ...userRegistration, [name]: value });
  };

  console.log(loginForm);
  // alertDialog
  const [alertMessage, setAlertMessage] = useState(false);

  // handler login

  const [loginUsers, setLoginUsers] = useState({
    phone: "",
    password: "",
    d_id: "window",
    token: "window token",
  });

  const loginOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginUsers({ ...loginUsers, [name]: value });
  };

  const loginUser = async (e) => {
    e.preventDefault();
    setAlertMessage(!alertMessage);
    const newLoginCredientials = { ...loginUsers };
    // API CAll
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newLoginCredientials),
      };
      const response = await fetch(`${host}/api/auth/login`, requestOptions);

      const data = await response.json();
      setAlertMessage(alertMessage);
      console.log(data);
      if (data.status === "Sucess") {
        localStorage.setItem("token", data.authtoken);
        localStorage.setItem("user_data", JSON.stringify(data.user_data));

        const com_data = data.com_data;
        if (com_data.length !== 0) {
          localStorage.setItem("com_id", com_data[0]._id);
          localStorage.setItem("com_name", com_data[0].com_name);
          setSelectedCompany({
            id: com_data[0]._id,
            name: com_data[0].com_name,
          });
        }
        // history("/mycompanies");
        window.location.reload();
      } else {
        props.showAlert("Something Went Wrong", "danger");
      }
    } catch (e) {
      props.showAlert(e.message, "danger");
    }
  };

  // handler crete

  const crateUser = async (e) => {
    e.preventDefault();
    setAlertMessage(!alertMessage);
    const newRecords = { ...userRegistration };

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newRecords),
      };
      const response = await fetch(
        `${host}/api/auth/createuser`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "Failed") {
        props.showAlert(data.error, "danger");
      } else {
        props.showAlert(data.msg, "success");
        changePanel();
      }
      console.log(data);
      setAlertMessage(alertMessage);
    } catch (error) {
      alert("Something Went wrong!");
    }
  };

  // otp login data

  const [otplogin, setotpLogin] = useState({
    phone: "",
  });
  const sendotp = async (e) => {
    e.preventDefault();
    // console.log(otplogin);
    if (e.target[7].innerText === "SEND OTP") {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(otplogin),
      };
      const response = await fetch(
        `${host}/api/auth/loginWithOtp`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "Failed") {
        props.showAlert(data.error, "danger");
      } else {
        props.showAlert(data.msg, "success");
        setotpVerify("showVerity");
        e.target[7].innerText = "VERIFY";
      }
      console.log(data);
      setAlertMessage(alertMessage);
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(otplogin),
      };
      const response = await fetch(
        `${host}/api/auth/verifyotp`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "Failed") {
        props.showAlert(data.msg, "danger");
      } else if (data.status === "Success") {
        props.showAlert(data.msg, "success");
        localStorage.setItem("token", data.authtoken);
        localStorage.setItem("user_data", JSON.stringify(data.user_data));
        // history("/mycompanies");
        window.location.reload();
      }
      console.log(data);
      setAlertMessage(alertMessage);
    }
  };

  const otploginInput = (e) => {
    setotpLogin({ ...otplogin, [e.target.name]: [e.target.value] });
  };

  // verify otp section
  const pinsetup1 = useRef(null);
  const pinsetup2 = useRef(null);
  const pinsetup3 = useRef(null);
  const pinsetup4 = useRef(null);
  const pinsetup5 = useRef(null);
  const pinsetup6 = useRef(null);

  const [otpData, setOtpData] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    digit5: "",
    digit6: "",
  });

  const otpvalueinput = (e) => {
    const re = /^[0-9]+$/;

    if (re.test(e.target.value)) {
      setOtpData({ ...otpData, [e.target.name]: [e.target.value] });

      if (e.target.dataset.next === "digit-2") {
        pinsetup2.current.focus();
        pinsetup2.current.value = "";
      }
      if (e.target.dataset.next === "digit-3") {
        pinsetup3.current.focus();
        pinsetup3.current.value = "";
      }
      if (e.target.dataset.next === "digit-4") {
        pinsetup4.current.focus();
        pinsetup4.current.value = "";
      }
      if (e.target.dataset.next === "digit-5") {
        pinsetup5.current.focus();
        pinsetup5.current.value = "";
      }
      if (e.target.dataset.next === "digit-6") {
        pinsetup6.current.focus();
        pinsetup6.current.value = "";
      }

      if (e.target.id === "digit-6") {
        let otp =
          otpData.digit1 +
          otpData.digit2 +
          otpData.digit3 +
          otpData.digit4 +
          otpData.digit5 +
          e.target.value;
        setotpLogin({ ...otplogin, otp: otp });
      }
    }
  };

  const verifyOtp = () => {
    console.log(otplogin);
  };

  if (localStorage.getItem("token")) {
    props.showAlert("You're Already Logged In", "success");
    history("/");
  }
  return (
    <>
      <div className="login-page">
        {alertMessage && <Preloader />}

        <div
          className={modal ? "container " : "container  right-panel-active"}
          id="container"
        >
          {/* ###################################################################
          Create account form secction is here
          ################################################################### */}
          <div className="form-container sign-up-container ">
            <form action="#" method="POST" onSubmit={crateUser}>
              <h1>Create Account</h1>

              <input
                type="text"
                className="form-control login-input search mb-3"
                autoComplete="off"
                name="fullname"
                value={userRegistration.fullname}
                onChange={handleInput}
                placeholder="Full Name"
              />
              <input
                type="phone"
                className="form-control login-input search mb-3"
                name="phone"
                value={userRegistration.phone}
                onChange={handleInput}
                autoComplete="off"
                placeholder="Phone"
              />
              <input
                type="email"
                className="form-control login-input search mb-3"
                name="email"
                value={userRegistration.email}
                onChange={handleInput}
                autoComplete="off"
                placeholder="Email"
              />
              <input
                type="password"
                className="form-control login-input search mb-3"
                name="password"
                value={userRegistration.pass}
                onChange={handleInput}
                autoComplete="off"
                placeholder="Create Password"
              />
              <button type="submit">Create Account</button>
            </form>
          </div>

          {/* ###################################################################
login with password section here
################################################################### */}

          <div className="form-container sign-in-container">
            <form
              action="#"
              className={loginForm ? "login_form-active" : ""}
              method="POST"
              onSubmit={loginUser}
            >
              <h1>Log in</h1>
              <div className="social-container">
                <p className="otpbutton" id="signUp" onClick={changeOtp}>
                  Log in with OTP
                </p>
              </div>
              <span>or</span>
              <input
                type="phone"
                className="form-control login-input search mb-3"
                placeholder="Phone"
                name="phone"
                autoComplete="off"
                onChange={loginOnChange}
              />
              <input
                type="password"
                className="form-control search login-input"
                placeholder="Password"
                name="password"
                onChange={loginOnChange}
              />
              <div className="forgetpass">Forgot your password?</div>
              <button disabled={loginUsers.password.length < 5} type="submit">
                Log In
              </button>
            </form>

            {/* ###################################################################
          login with otp section is here
          ################################################################### */}
            <form
              className={loginForm ? "" : "login_form-active"}
              onSubmit={sendotp}
            >
              <h1>Log in</h1>
              <p id="signUp" onClick={changeOtp}>
                Log in with Password
              </p>
              <span>or</span>
              <input
                type="phone"
                className="form-control login-input search mb-3"
                placeholder="Enter Phone No."
                name="phone"
                onChange={otploginInput}
              />
              <div className={`otpInput ${otpVerify}`}>
                <input
                  type="text"
                  className="digit-group"
                  autoComplete="off"
                  name="digit1"
                  onChange={otpvalueinput}
                  ref={pinsetup1}
                  maxLength={1}
                  data-next="digit-2"
                  id="digit-1"
                />
                <input
                  type="text"
                  className="digit-group"
                  autoComplete="off"
                  name="digit2"
                  onChange={otpvalueinput}
                  ref={pinsetup2}
                  maxLength={1}
                  data-next="digit-3"
                  id="digit-2"
                />
                <input
                  type="text"
                  className="digit-group"
                  autoComplete="off"
                  name="digit3"
                  onChange={otpvalueinput}
                  ref={pinsetup3}
                  maxLength={1}
                  data-next="digit-4"
                  id="digit-3"
                />
                <input
                  type="text"
                  className="digit-group"
                  autoComplete="off"
                  name="digit4"
                  onChange={otpvalueinput}
                  ref={pinsetup4}
                  maxLength={1}
                  data-next="digit-5"
                  id="digit-4"
                />
                <input
                  type="text"
                  className="digit-group"
                  autoComplete="off"
                  name="digit5"
                  onChange={otpvalueinput}
                  ref={pinsetup5}
                  maxLength={1}
                  data-next="digit-6"
                  id="digit-5"
                />
                <input
                  type="text"
                  className="digit-group"
                  autoComplete="off"
                  name="digit6"
                  onChange={otpvalueinput}
                  ref={pinsetup6}
                  maxLength={1}
                  data-next="digit-1"
                  id="digit-6"
                />
              </div>
              <button type="submit">Send OTP</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>To keep connected with us please login with your info</p>
                <button className="ghost" id="signIn" onClick={changePanel}>
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello,</h1>
                <p>Enter your details and start journey with us</p>
                <button className="ghost" id="signUp" onClick={changePanel}>
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
